<template>
  <div v-if=" $i18n.locale === 'ZH'" class="page2 flex-col">
    <div class="box_1 flex-row">
      <div class="group_13 flex-col">
        <div class="group_14 flex-row justify-between">
          <div class="box_2 flex-col"></div>
          <span class="text_1">导航</span>
        </div>
      </div>
      <div class="box_3 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="block_1 flex-col">
      <div class="group_1 flex-col">
        <div class="box_5 flex-col">
          <div class="group_2 flex-col"></div>
          <div class="group_3 flex-col">
            <div class="group_4 flex-col">
              <div class="group_5 flex-col"></div>
              <span class="text_5">突破界限&nbsp;&nbsp;学无止境</span>
            </div>
          </div>
        </div>
      </div>
      <div class="middleTitle">
        <span class="text_6">用数字说话</span>
        <span class="text_7 eng">/HIGHLIGHTS</span>
      </div>
    </div>
    <div class="box_6 flex-col">
      <div class="group_15 flex-row justify-between">
        <div class="text-wrapper_3" style="overflow-y: scroll">
          <span class="paragraph_1">
            双料学位起点高
            <br/>
          </span>
          <span class="text_8">
            上海纽约大学是教育部批准设立，由中美一流大学合作创办的第一所具有独立法人资格的高等学府，也是纽约大学全球教育体系的组成部分，与纽约校园、阿布扎比校园共同组成该体系中具有学位授予权的三大校园。学生毕业后将获得中美双学位，共三张证书：即由美国纽约大学颁发的学位证书，以及由上海纽约大学颁发的学位证书和毕业证书。毕业后无论是出国深造，还是国内就业，都可发挥中美两套文凭带来的优势和便利。
          </span>
          <div></div>
          <span class="paragraph_1">
            学游全球无国界
            <br/>
          </span>
          <span class="text_8">
             所有学生将有保障地进行海外学习，在纽约校园、阿布扎比校园及12个海外学习中心组成的纽约大学全球体系间自由流动。上海纽约大学的中国学生和国际学生各半，国际学生来自全球70多个国家和地区，是全球国际化程度最高的大学之一。   </span>
          <div></div>
          <span class="paragraph_1">
            专业发展不设限
            <br/>
          </span>
          <span class="text_8">
           学生将在博雅教育核心课程的框架下，充分了解各专业内容与前景，最早可于大一结束前、最晚于大二结束前自主选定专业。伴随着细致的学业辅导与定制化的学术路径，学生将深入探索最契合自身学术与职业发展兴趣的专业领域。   </span>
        </div>
        <div class="section_2 flex-col"></div>
      </div>
      <div class="group_16 flex-row" style="overflow-x: scroll">
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="编组 11备份 (1)"></svg-icon>
            <span class="text-group_1">2</span>
          </div>
          <span class="text_21">毕业生将获中美两张学位证书</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="pag1 (4)"></svg-icon>
            <span class="text-group_1" style="">1:8</span>
          </div>
          <span class="text_21">师生比将长期保持在1比8以内，保证小班化教学优势</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (5)"></svg-icon>
            <span class="text-group_1">20</span>
          </div>
          <span class="text_21">20人以下的小班课程，占全部课程的四分之三</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="topchange"></svg-icon>
            <span class="text-group_1">Top10</span>
          </div>
          <span class="text_21">
            纽约大学的数学、商学、哲学、神经科学、数据科学、法学、医学等多个学科均位列全球前十，学校综合排名稳定在世界前30位</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (8)"></svg-icon>
            <span class="text-group_1">3+12</span>
          </div>
          <span class="text_21">
                      纽约大学全球体系的三大门户校园与12个海外学习中心，遍布全球五大洲
          </span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (9)"></svg-icon>
            <span class="text-group_1">50</span>
          </div>
          <span class="text_21">近20年来，纽约大学共产生5位诺贝尔奖得主、5位普利策奖得主、4位阿贝尔奖得主、7位麦克阿瑟奖得主，以及29位美国两院院士</span>
        </div>

        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (7)"></svg-icon>
            <span class="text-group_1">1800+</span>
          </div>
          <span class="text_21">
        在校本科生人数 </span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (3)"></svg-icon>
            <span class="text-group_1">70+</span>
          </div>
          <span class="text_21">学生来自全球70多个国家和地区</span>
        </div>
        <div style="min-width: 10vw" >

        </div>
      </div>
    </div>
    <div class="block_2 flex-col">
      <div class="middleTitle">
        <span class="text_6">学生感言</span>
        <span class="text_7 eng">/STUDENT&nbsp;JOURNEY</span>
      </div>
    </div>
    <div>
      <nut-collapse style="width: 95vw" v-model="activeNames" >
        <nut-collapse-item title="施怡聆&nbsp;2022届" :name="1">
          <div class="text_18">上纽入学时不分专业，这一点对我来说很重要。它给了我充分的选择空间和体验时间来探索自己的兴趣。</div>
          <div class="text-wrapper_5 flex-col">
            <div class="main">
              <div style="    margin: 2vw;">
                              <span class="paragraph_2">
          毕业中学：华东师范大学第二附属中学
          <br/>
          <br/>
          本科专业：金融学（商学与金融）、世界史（社会科学）（自主设计城市研究方向）
          <br/>
          <br/>
          辅修：公共政策与管理
          <br/>
          <br/>
          毕业去向：安永中国金融服务咨询团队
        </span>
              </div>

            </div>

          </div>
        </nut-collapse-item>
      </nut-collapse>
    </div>
    <myfooter></myfooter>
  </div>
  <div v-else class="page2 flex-col eng">
    <div class="box_1 flex-row">
      <div class="group_13 flex-col">
        <div class="group_14 flex-row justify-between">
          <div class="box_2 flex-col"></div>
          <span class="text_1">导航</span>
        </div>
      </div>
      <div class="box_3 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="block_1 flex-col">
      <div class="group_1 flex-col">
        <div class="box_5 flex-col">
          <div class="group_2 flex-col"></div>
          <div class="group_3 flex-col">
            <div class="group_4 flex-col" style="    padding: 13.06vw 9.6vw 6.7vw 4.26vw;" >
              <div class="group_5 flex-col"></div>
              <div class="text_5" style="font-size:5vw">PUSH BOUNDARIES
                <div  style="font-size: 5vw">LEARN WITHOUT LIMITS</div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middleTitle">
        <span class="text_6">HIGHLIGHTS</span>
      </div>
    </div>
    <div class="box_6 flex-col">
      <div class="group_15 flex-row justify-between">
        <div class="text-wrapper_3" style="overflow-y: scroll">
          <span class="paragraph_1">
            IMMERSE YOURSELF IN A NEW

            <br/>
          </span>
          <span class="text_8">
    NYU Shanghai students have a genuine interest in China and beyond and an openness to experience new cultures. From food and language learning to China’s role in the global economy, as a student here, you’ll be immersed in Chinese culture during your everyday life.    </span>
          <div></div>
 </div>
        <div class="section_2 flex-col"></div>
      </div>
      <div class="group_26 flex-row  justify-between" style="overflow-x: scroll">
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" >
            <svg-icon style="margin-top: -1.5vw" :svgStyle="{'width':'1.5em','height':'1.5em' }" icon-class="page1EN-1"></svg-icon>
            <span class="text-group_1" style="margin: 1.26vw 4vw 0 4.26vw"></span>
          </div>
          <span class="text_21 eng">NYU Shanghai is the first undergraduate
        university in China to award both US and Chinese degrees</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="page1EN-2"></svg-icon>
            <span class="text-group_1" style="">28</span>
          </div>
          <span class="text_21 eng">provinces of China
represented</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="page1EN-3"></svg-icon>
            <span class="text-group_1" style="">1:6</span>
          </div>
          <span class="text_21 eng">40 US states represented</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="page1EN-4"></svg-icon>
            <span class="text-group_1" style="">50+</span>
          </div>
          <span class="text_21 eng">The number of languages known</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="pag1 (4)"></svg-icon>
            <span class="text-group_1" style="">6:1</span>
          </div>
          <span class="text_21 eng">student-faculty ratio</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (8)"></svg-icon>
            <span class="text-group_1">29%</span>
          </div>
          <span class="text_21 eng">
           students are
from the United States
          </span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (3)"></svg-icon>
            <span class="text-group_1">60+</span>
          </div>
          <span class="text_21 eng">countries represented</span>
        </div>
      </div>

    </div>
    <div class="block_2 flex-col">
      <div class="middleTitle">
        <span class="text_6 eng">STUDENT JOURNEY</span>
      </div>
    </div>
    <div>
      <nut-collapse style="width: 95vw" v-model="activeNames" >
        <nut-collapse-item title="Aicha Hanne, Class of 2021" :name="1">
          <div class="text_18" style="min-height: 40vw">
            “At NYU Shanghai, you get to know and connect with everyone. I’ve had roommates who are Chinese and I’ve made friends from all over the world—Nepal, Pakistan, Trinidad and Tobago, the United States, and so many other places.”
          </div>
<!--          <div class="text-wrapper_5 flex-col">-->
<!--            <div class="main">-->
<!--              <div style="    margin: 2vw;">-->
<!--                              <span class="paragraph_2">-->
<!--          毕业中学：华东师范大学第二附属中学-->
<!--          <br/>-->
<!--          <br/>-->
<!--          本科专业：金融学（商学与金融）、世界史（社会科学）（自主设计城市研究方向）-->
<!--          <br/>-->
<!--          <br/>-->
<!--          辅修：公共政策与管理-->
<!--          <br/>-->
<!--          <br/>-->
<!--          毕业去向：安永中国金融服务咨询团队-->
<!--        </span>-->
<!--              </div>-->

<!--            </div>-->

<!--          </div>-->
        </nut-collapse-item>
      </nut-collapse>
    </div>
    <myfooter :eng="true"></myfooter>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNames: [1, 2],
      constants: {}
    };
  },
  methods: {},
  mounted() {
    this.$emit('changeView')
  }

};
</script>
<style scoped lang="scss" src="./assets/index.response.scss"/>
