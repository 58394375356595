<template>
  <div v-if=" $i18n.locale === 'ZH'" class="page11 flex-col">
    <div class="nav-bar_1 flex-row">
      <div class="box_20 flex-col">
        <div class="section_5 flex-row justify-between">
          <div class="icon_1 flex-col"></div>
          <span class="text_1">导航</span>
        </div>
      </div>
      <div class="box_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="group_5 flex-col">
      <div class="box_4 flex-col">
        <div class="box_5 flex-col">
          <div class="group_1 flex-col"></div>
          <div class="group_2 flex-col">
            <div class="section_2 flex-col">
              <div class="box_6 flex-col"></div>
              <span class="paragraph_1" style="width: 60vw">
                加入上海纽约大学
                <br />
                让世界成为你的课堂
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-wrapper_14 flex-row justify-between" style="width: auto;margin: 10.13vw 25.86vw 0 15.73vw">
        <span class="text_5">申请流程</span>
        <span class="text_6 eng">/APPLICATION PROCEDURE</span>
      </div>

      <div class="box_7 flex-row justify-between">
        <span class="text_7">10月起</span>
        <span class="text_8">完成英语语言测试、在线申请并寄送纸质申请材料</span>
        <div class="box_8 flex-row justify-between">
          <span class="paragraph_2">
            次年
            <br />
            1月1日
          </span>
          <span class="text_9">申请截止</span>
          <div class="block_1 flex-row justify-between">
            <span class="paragraph_3">
              次年
              <br />
              2月初
            </span>
            <span class="text_10">获知初审结果</span>
            <div class="text-wrapper_3 flex-row justify-between">
              <span class="paragraph_4">
                次年
                <br />
                2月或3月
              </span>
              <span class="text_11">参加校园日活动</span>
              <div class="group_6 flex-row justify-between">
                <span class="text_12">
                  次年
                  <br />
                  春季
                </span>
                <span class="paragraph_5">
                  获知录取资格
                  <br />
                  (预录取、待录取、淘汰)
                </span>
                <div class="text-wrapper_15 flex-row justify-between">
                  <span class="paragraph_9">
                    次年
                    <br />
                    6月
                  </span>
                  <span class="text_13">参加高考</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="text_14">2023年本科入学申请文书题目</span>
      <div class="box_11 flex-col"></div>
      <div class="text-wrapper_5 flex-col"><span class="text_15">1</span></div>
      <span class="text_16 eng">
        Choose the option that best helps you answer that question and write an essay of no more than 650 words, using the prompt to inspire and structure your response. Remember: 650 words is your limit, not your goal. Use the full range if you need it, but don't feel obligated to do so. You can type directly into the box, or you can paste text from another source.
      </span>
      <div class="box_12 flex-col"></div>
      <div class="text-wrapper_16 flex-col">
        <span class="text_17 eng">
          Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story.
        </span>
      </div>
      <div class="text-wrapper_17 flex-row justify-between">
<!--        <span class="text_21"></span>-->
<!--        <span class="text_22"></span>-->
      </div>
      <div class="middleTitle">
        <span class="text_6">相关费用</span>
        <span class="text_7 eng">/tuition</span>
      </div>
      <div class="box_13 flex-col">
        <div class="text-wrapper_18 flex-row justify-between">
          <span class="text_23">学费</span>
          <span class="text_24">生活费</span>
        </div>
        <div class="group_7 flex-row justify-between">
          <div class="box_21 flex-col">
            <div class="text-group_6 flex-col">
              <span class="text_25">¥200,000</span>
              <span class="text_26">第一、第二每学年</span>
            </div>
            <div class="text-group_7 flex-col">
              <span class="text_27">¥230,000</span>
              <span class="text_28">第三、第四每学年</span>
            </div>
          </div>
          <div class="text-group_8 flex-col">
            <span class="paragraph_6">
              ¥50,000
              <br />
               &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;~
              <br />
              ¥60,000
            </span>
            <span class="text_29">2022-2023学年预估</span>
          </div>
        </div>
      </div>
      <div class="box_14 flex-col">
        <div class="text-wrapper_12 flex-col"><span class="text_30">申请文书</span></div>
        <span class="paragraph_7">
          ·&nbsp;第一篇为选题写作
          <br />
          （四选一，不超过650个单词）
          <br />
          <br />
          ·&nbsp;第二篇为开放式写作
          <br />
          （不超过400个单词），内容
          <br />
          &nbsp;&nbsp;不限。
        </span>
      </div>
      <div class="box_15 flex-col" style="    height: 53vw;">
        <div class="text-wrapper_13 flex-col"><span class="text_31">2</span></div>
        <span class="text_32 eng" >
         Beyond the information you have already included in your application, what else do you want the Admissions Committee to know about you? (Maximum 400 words)   </span>
      </div>
      <div class="text-wrapper_19 flex-col eng">
        <span class="text_18">
          The lessons we take from obstacles we encounter can be fundamental to later success. Recount a time when you faced a challenge, setback, or failure. How did it affect you, and what did you learn from the experience?
        </span>
      </div>
      <div class="text-wrapper_20 flex-col eng">
        <span class="text_19">
          Reflect on a time when you questioned or challenged a belief or idea. What prompted your thinking? What was the outcome?
        </span>
      </div>
      <div class="text-wrapper_21 flex-col eng">
        <span class="text_20">
          Discuss an accomplishment, event, or realization that sparked a period of personal growth and a new understanding of yourself or others.
        </span>
      </div>
      <div class="text-wrapper_22 flex-row justify-between">
        <span class="paragraph_10">
          次年
          <br />
          7月
        </span>
        <span class="text_37">正式录取</span>
      </div>
    </div>
    <div class="box_16 flex-col">
      <div class="text-group_9 flex-col">
        <span class="text_33">奖学金政策</span>
        <span class="text_34">
          对于被上海纽约大学录取的学生，学校将综合考虑学生的学业水平和“校园日活动”表现等因素，为优秀的学生提供奖学金。对于特别突出的学生，奖学金最高可覆盖四年的学费和基本生活费。
        </span>
      </div>
      <div class="text-group_10 flex-col">
        <span class="text_35">助学金政策</span>
        <span class="paragraph_8">
          学校不希望被录取的任何一名学生因家庭经济困难，失去在上海纽约大学学习发展的机会。对于被上海纽约大学录取，但家庭无法承担学费和生活费的学生，在学生按要求提供真实完整的家庭经济状况材料后，学校将酌情提供助学金。
          <br />
          <br />
          <br />
          由于每位学生的具体家庭情况有所不同，所以最终获得的助学金金额也不尽相同。助学金与学习成绩无关，只要家庭经济压力较大均可申请。助学金不是贷款，无需偿还。
        </span>
      </div>
    </div>
      <myfooter style="margin-top: 5vw"></myfooter>
  </div>
  <div v-else class="page11EN eng flex-col">
    <div class="box_3 flex-col"><div class="block_3 flex-col"></div></div>
    <div class="group_44 flex-col">
      <span class="text_6" style="margin: 58.4vw auto 0 auto;width: auto">SUBMITTING&nbsp;YOUR&nbsp;APPLICATION</span>
      <div class="box_14 flex-row justify-between">
        <div class="text-wrapper_2 flex-col"><span class="text_7">Application&nbsp;Plan</span></div>
        <div class="text-wrapper_3 flex-col"><span class="text_8">Application&nbsp;Deadline</span></div>
      </div>
      <div class="box_15 flex-row justify-between">
        <div class="text-wrapper_4 flex-col"><span class="text_9">Early&nbsp;Decision&nbsp;I</span></div>
        <div class="text-wrapper_5 flex-col"><span class="text_10">November&nbsp;1</span></div>
      </div>
      <div class="box_16 flex-row justify-between">
        <div class="text-wrapper_6 flex-col"><span class="text_11">Early&nbsp;Decision&nbsp;II</span></div>
        <div class="text-wrapper_7 flex-col"><span class="text_12">January&nbsp;1</span></div>
      </div>
      <div class="box_17 flex-row justify-between">
        <div class="text-wrapper_8 flex-col"><span class="text_13">Regular&nbsp;Decision</span></div>
        <div class="text-wrapper_9 flex-col"><span class="text_14">January&nbsp;5</span></div>
      </div>
      <div class="box_18 flex-row justify-between">
        <div class="text-wrapper_10 flex-col"><span class="text_15">Application&nbsp;Plan</span></div>
        <div class="text-wrapper_11 flex-col"><span class="text_16">Financial&nbsp;Aid&nbsp;Deadline*</span></div>
      </div>
      <div class="box_19 flex-row justify-between">
        <div class="text-wrapper_12 flex-col"><span class="text_17">Early&nbsp;Decision&nbsp;I</span></div>
        <div class="text-wrapper_13 flex-col"><span class="text_18">November&nbsp;15</span></div>
      </div>
      <div class="box_20 flex-row justify-between">
        <div class="text-wrapper_14 flex-col"><span class="text_19">Early&nbsp;Decision&nbsp;II</span></div>
        <div class="text-wrapper_15 flex-col"><span class="text_20">January&nbsp;15</span></div>
      </div>
      <div class="box_21 flex-row justify-between">
        <div class="text-wrapper_16 flex-col"><span class="text_21">Regular&nbsp;Decision</span></div>
        <div class="text-wrapper_17 flex-col"><span class="text_22">February&nbsp;20</span></div>
      </div>
      <div class="box_22 flex-row justify-between">
        <div class="text-wrapper_18 flex-col"><span class="text_23">Application&nbsp;Plan</span></div>
        <div class="text-wrapper_19 flex-col">
          <span class="text_24">Notification&nbsp;Date&nbsp;(approximate)</span>
        </div>
      </div>
      <div class="box_23 flex-row justify-between">
        <div class="text-wrapper_20 flex-col"><span class="text_25">Early&nbsp;Decision&nbsp;I</span></div>
        <div class="text-wrapper_21 flex-col"><span class="text_26">December&nbsp;15</span></div>
      </div>
      <div class="box_24 flex-row justify-between">
        <div class="text-wrapper_22 flex-col"><span class="text_27">Early&nbsp;Decision&nbsp;II</span></div>
        <div class="text-wrapper_23 flex-col"><span class="text_28">February&nbsp;15</span></div>
      </div>
      <div class="box_25 flex-row justify-between">
        <div class="text-wrapper_24 flex-col"><span class="text_29">Regular&nbsp;Decision</span></div>
        <div class="text-wrapper_25 flex-col"><span class="text_30">April&nbsp;1</span></div>
      </div>
      <div class="section_20 flex-col">
        <div class="text-wrapper_26">

          <span class="text_32">
            <div>
               <span class="text_31">*</span>   When submitting the FAFSA, be sure to use NYU’s school code: 002785
            </div>
          <div>
            For more information on filing the FAFSA as well as aid and scholarships, visit
                <a href="https://nyu.edu/financial.aid" target="_self">
                  <span class="text_31">nyu.edu/financial.aid.</span>
                </a>
          </div>
          </span>

        </div>
      </div>
      <span class="paragraph_2">
        FORMS REQUIRED FOR FINANCIAL
        AID CONSIDERATION
      </span>

      <div class="box_26 flex-row justify-between">
        <div class="text-wrapper_28 flex-col"><span class="text_40">Citizenship/Campus</span></div>
        <div class="text-wrapper_29 flex-col"><span class="text_41" style="    margin: 2.66vw 0 0 13.73vw;">New York City</span></div>
      </div>
      <div class="box_27 flex-row justify-between">
        <div class="text-wrapper_30 flex-col">
          <span class="text_42">US&nbsp;Citizens/Permanent&nbsp;Residents</span>
        </div>
        <div class="text-wrapper_31 flex-col"><span class="text_43">FAFSA/CSS Profile</span></div>
      </div>
      <div class="box_28 flex-row justify-between">
        <div class="text-wrapper_32 flex-col">
          <span class="text_44">Non-US&nbsp;Citizens/Non-US&nbsp;Permanent&nbsp;Residents</span>
        </div>
        <div class="text-wrapper_33 flex-col"><span class="text_45">CSS&nbsp;Profile**</span></div>
      </div>
      <div class="box_26 flex-row justify-between">
        <div class="text-wrapper_28 flex-col"><span class="text_40">Citizenship/Campus</span></div>
        <div class="text-wrapper_29 flex-col"><span class="text_41">Abu&nbsp;Dhabi</span></div>
      </div>
      <div class="box_27 flex-row justify-between">
        <div class="text-wrapper_30 flex-col">
          <span class="text_42">US&nbsp;Citizens/Permanent&nbsp;Residents</span>
        </div>
        <div class="text-wrapper_31 flex-col"><span class="text_43">CSS&nbsp;Profile</span></div>
      </div>
      <div class="box_28 flex-row justify-between">
        <div class="text-wrapper_32 flex-col">
          <span class="text_44">Non-US&nbsp;Citizens/Non-US&nbsp;Permanent&nbsp;Residents</span>
        </div>
        <div class="text-wrapper_33 flex-col"><span class="text_45">CSS&nbsp;Profile**</span></div>
      </div>
      <div class="box_29 flex-row justify-between">
        <div class="text-wrapper_34 flex-col"><span class="text_46">Citizenship/Campus</span></div>
        <div class="text-wrapper_35 flex-col"><span class="text_47">Shanghai</span></div>
      </div>
      <div class="box_30 flex-row justify-between">
        <div class="text-wrapper_36 flex-col">
          <span class="text_48">US&nbsp;Citizens/Permanent&nbsp;Residents</span>
        </div>
        <div class="text-wrapper_37 flex-col"><span class="text_49">FAFSA/CSS&nbsp;Profile</span></div>
      </div>
      <div class="box_31 flex-row justify-between">
        <div class="text-wrapper_38 flex-col">
          <span class="text_50">Non-US&nbsp;Citizens/Non-US&nbsp;Permanent&nbsp;Residents</span>
        </div>
        <div class="text-wrapper_39 flex-col"><span class="text_51">CSS&nbsp;Profile**</span></div>
      </div>
      <div class="section_20 flex-col">
        <div class="text-wrapper_26" style="margin: 9.46vw 0 0 7.2vw;">

          <span class="text_32" style="top: 3vw">
            <div>
               <span class="text_31">**</span>              Chinese citizens applying to NYU Shanghai should not submit either
the CSS Profile or the FAFSA.
            </div>
          <div>

          </div>
          </span>

        </div>
      </div>
      <span class="text_54">COST&nbsp;OF&nbsp;ATTENDANCE</span>
      <div class="group_24 flex-row">
        <div class="text-group_17 flex-col justify-between">
          <span class="text_55">$56,500</span>
          <span class="text_56">Average tuition and fees for two semesters</span>
        </div>
        <div class="block_1 flex-col"></div>
        <div class="text-group_18 flex-col justify-between">
          <span class="text_57">$7,104</span>
          <span class="paragraph_3">
            Average room&nbsp;
            <br />
            and board per year
          </span>
        </div>
      </div>
      <div class="section_27 flex-col" >
        <div class="text-wrapper_41" style="width: auto">
          <span class="text_58">JOIN</span>
          <span class="paragraph_4" style="width: auto">
            <br />
            THE&nbsp;BEST&nbsp;AND&nbsp;BRIGHTEST
          </span>
        </div>
      </div>
    </div>
    <div class="box_5 flex-col" style="margin: 10.74vw 0 0 3.2vw;">
      <div class="text-group_19 flex-col justify-between">
        <span class="text_59" style="width: auto">SUPPORTING OUR STUDENTS</span>
        <span class="text_60">
   NYU Shanghai is committed to providing educational opportunities to all talented students. We encourage all applicants to apply for financial aid (regardless of citizenship).
        </span>
      </div>
      <div class="text-group_20 flex-col justify-between">
        <span class="paragraph_5" style="width: auto;font-size: 4.26vw;" >
          FINANCIAL&nbsp;AID&nbsp;FOR&nbsp;APPLICANTS&nbsp;
          <br />
          OUTSIDE&nbsp;CHINA
        </span>
        <div class="paragraph_6">
          <span class="paragraph_7">
            <div>
                          Financial aid may take the form of university scholarships and/or grants, federal aid (for U.S. citizens/official permanent residents of the United States), or outside scholarships.

            </div>
            <br />
<div>
              For most undergraduates, eligibility for limited merit-based scholarships and/or need-based grants are determined based on your prior academic strengths and on your demonstrated financial need, if you apply for financial aid.

</div>

            <br />
            <div>
                          If you wish to be reviewed for need-based institutional funding, you will need to file the

            </div>
          </span>
          <span class="text_63">CSS Profile</span>
          <span class="paragraph_8">
             online by our required deadlines.
            <br />
          </span>
        </div>
      </div>
    </div>
    <a href="https://connect.nyu.edu/register/?id=eba99bd7-70d5-4e7d-987d-85971a1c2bec&utm_source=website&utm_medium=button&utm_campaign=mobile-travel-piece" target="_self">
      <div class="button_1 flex-col" ><span class="text_61">JOIN OUR MAILING LIST!</span></div>
    </a>
    <myfooter style="margin-top: 5vw" :eng="true">

    </myfooter>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  mounted() {
    this.$emit('changeView')
  },
  methods: {}
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss" />
