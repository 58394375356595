<template>
  <div v-if=" $i18n.locale === 'ZH'" class="page4 flex-col">
    <div class="box_1 flex-row">
      <div class="group_1 flex-col"></div>
      <span class="text_1"></span>
      <div class="group_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2"></span>
        <span class="text_3"></span>
        <span class="text_4"></span>
      </div>
    </div>
    <div class="group_7 flex-col">
      <div class="block_1 flex-col">
        <div class="group_3 flex-col">
          <div class="box_3 flex-col"></div>
          <div class="box_4 flex-col">
            <div class="box_5 flex-col">
              <div class="block_2 flex-col"></div>
              <span class="text_5">师承名家&nbsp;教学相长</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-wrapper_7 flex-row justify-between">
        <span class="text_6">师资团队</span>
        <span class="text_7 eng">/FACULTY</span>
      </div>
    </div>

    <div class="box_6 flex-col">
      <div class="block_3 flex-row justify-between" style="overflow-y: scroll">
        <span class="paragraph_1">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;上海纽约大学按照纽约大学师资聘用标准面向全球招聘，已建成一支国际化、高水平、结构合理的师资队伍。引进的教师100%具有在国际一流高校执教或研究工作经验。师生比保持在1:8之内，处于世界领先水平。
          <br/>
          <br/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;办学至今，先后有26位各国院士级的世界学术领军人在校全时任职，为本科生直接授课。一流的师资和极致的小班化教学模式，为学生创造最优质高效的教学体验，实现最大化的学习成果。在海外学习期间，无论前往任何一处校园或学习中心，学生将继续享受纽约大学全球顶尖的师资力量。
        </span>
      </div>
      <div class="group_16 flex-row justify-between" style="overflow-x: scroll">
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (3)"></svg-icon>
            <span class="text-group_1">29</span>
          </div>
          <span class="text_21">教授来自国家</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="page4 (3)"></svg-icon>
            <span class="text-group_1">256</span>
          </div>
          <span class="text_21">
            全职教师
          </span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="page4 (4)"></svg-icon>
            <span class="text-group_1" style="">30%</span>
          </div>
          <span class="text_21">
            教授入选国家级或省级高端人才项目
          </span>
        </div>

        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="page4 (5)"></svg-icon>
            <span class="text-group_1">23</span>
          </div>
          <span class="text_21">
            外国院、会士学者，以及诺贝尔奖得主
          </span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="page4 (1)"></svg-icon>
            <span class="text-group_1">48</span>
          </div>
          <span class="text_21">
              覆盖学科领域
          </span>
        </div>
        <div style="min-width: 10vw"></div>
      </div>
    </div>
    <div class="group_8 flex-col">
      <span class="paragraph_2" style="width: auto;margin: 0">
        2022-2023学年部分
        <br/>
        在校任教或开展科研工作的世界顶级学者
      </span>
      <div class="box_13 flex-col">
        <a :href="dataImgItem[swiperIndex].link">
          <div class="text-group_8 flex-col">
            <span class="text_15" style="border-bottom: 0.5vw solid rgba(61,4,98,0.77)">{{
                dataImgItem[swiperIndex].name
              }}</span>
            <span class="text_16">{{ dataImgItem[swiperIndex].tag }}</span>
          </div>
        </a>
        <swiper
            style=" width: 50vw ;"
            :centeredSlides="true"
            :pagination="{clickable: true,}"
            class="mySwiper"
            @slideChange="handleChangeSwiper"
            :options="swiperOption"
            ref="mySwiper"
        >
          <swiper-slide v-for="(item,index) in dataImgItem" :key="index"
                        style="padding: 0 3vw ;background-color: transparent">
            <img :src="item.imgSrc" style="border-radius: 2vw ; scale: 0.8; height: 56%">
          </swiper-slide>
        </swiper>

      </div>

      <div style="color:#7F7F7C;display: flex;justify-content: center;font-size: 2vw">
        <span>
          滑动查看更多
        </span>
      </div>
      <div class="middleTitle">
        <span class="text_6">学生感言</span>
        <span class="text_7 eng">/STUDENT&nbsp;JOURNEY</span>
      </div>
      <div>
        <nut-collapse style="width: 95vw" v-model="activeNames">
          <nut-collapse-item title="陈昕玥&nbsp;2022届" :name="2">
            <div class="cardA">
              在上海纽约大学的学习过程中，教授提出什么问题，我只要有想法都会讲出来，不用担心是不是说错了。有时，即便同学们的答案“只是沾了点边”，也会得到教授的鼓励与夸奖，这给了我很大的信心。
            </div>
            <div class="cardB flex-col">
              <div class="cardC">
                <div style="    margin: 2vw;">
                                        <span class="paragraph_2">
                                             <br/>
                                            毕业中学：上海市实验学校
                                            <br/>
                                            <br/>
                                            本科专业：主修计算机科学与技术（计算机科学
                                            <br/>
                                            <br/>
                                            辅修：公共政策与管理
                                            <br/>
                                            <br/>
                                            毕业去向：安永中国金融服务咨询团队
                                          </span>
                </div>

              </div>

            </div>
          </nut-collapse-item>
<!--          <nut-collapse-item title="盖宇&nbsp;2019届" :name="3">-->
<!--            <div class="cardA">-->
<!--              离开上海纽约大学，我最怀念的就是学校里平易近人、学识渊博的“大牛”教授。我觉得，上海纽约大学有很多杰出的教授，虽然他们讲的课程不同，但都有相似之处——能将很复杂的问题以高屋建瓴的方式讲清楚，然后再带着同学一起剖析技术性的细节。-->
<!--            </div>-->
<!--            <div class="cardB flex-col">-->
<!--              <div class="cardC">-->
<!--                <div style="    margin: 2vw;">-->
<!--                                        <span class="paragraph_2">-->
<!--                                                                           <br/>-->

<!--                                  毕业中学：上海七宝中学-->
<!--                                  <br/>-->
<!--                                  <br/>-->
<!--                                  本科专业：计算机科学-->
<!--                                  <br/>-->
<!--                                  <br/>-->
<!--                                  毕业去向：加州大学伯克利分校  计算机科学博士研究生-->
<!--                                          </span>-->
<!--                </div>-->

<!--              </div>-->

<!--            </div>-->
<!--          </nut-collapse-item>-->
        </nut-collapse>
        <nut-collapse style="width: 95vw" v-model="activeNamesEl">

          <nut-collapse-item title="盖宇&nbsp;2019届" :name="3">
            <div class="cardA">
              离开上海纽约大学，我最怀念的就是学校里平易近人、学识渊博的“大牛”教授。我觉得，上海纽约大学有很多杰出的教授，虽然他们讲的课程不同，但都有相似之处——能将很复杂的问题以高屋建瓴的方式讲清楚，然后再带着同学一起剖析技术性的细节。
            </div>
            <div class="cardB flex-col">
              <div class="cardC">
                <div style="    margin: 2vw;">
                                        <span class="paragraph_2">
                                                                           <br/>

                                  毕业中学：上海七宝中学
                                  <br/>
                                  <br/>
                                  本科专业：计算机科学
                                  <br/>
                                  <br/>
                                  毕业去向：加州大学伯克利分校  计算机科学博士研究生
                                          </span>
                </div>

              </div>

            </div>
          </nut-collapse-item>
        </nut-collapse>
      </div>
    </div>

    <myfooter></myfooter>
  </div>
  <div v-else class="page4copy flex-col eng">
    <div class="box_1 flex-col">
      <div class="box_2 flex-col"></div>
    </div>
    <div class="text-wrapper_1 flex-col">
      <span class="paragraph_1">
        YOUR&nbsp;PROFESSORS&nbsp;
        <br/>
        YOUR&nbsp;MENTORS
      </span>
    </div>
    <div class="box_3 flex-col">
      <span class="text_1">
        NYU Shanghai is the intellectual home of 333 of the world’s best teachers, scholars, researchers, and mentors. And because class sizes are small (the student-to-faculty ratio is 6-to-1), your professors are able to provide you with individualized academic guidance, advise you on research projects, help you connect with professionals in your field, and serve as career mentors in the future.
      </span>
      <div class="box_4 flex-row justify-between">
        <div class="group_1 flex-row">
          <div class="image-text_1 flex-col">
            <div class="group_2 flex-col"></div>
            <div class="text-group_1 flex-col">
              <span class="text_2">29</span>
              <span class="text_3">nationalities&nbsp;represented</span>
            </div>
          </div>
        </div>
        <div class="group_3 flex-row">
          <div class="image-text_2 flex-col">
            <div class="group_4 flex-col"></div>
            <div class="text-group_2 flex-col">
              <span class="text_4">333</span>
              <span class="text_5">faculty&nbsp;members</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box_5 flex-row justify-between">
        <div class="text-wrapper_2">
          <span class="text_6">38</span>
          <span class="text_7">%</span>
        </div>
        <div class="text-wrapper_3">
          <span class="text_8">62</span>
          <span class="text_9">%</span>
        </div>
      </div>
      <div class="text-wrapper_4 flex-row justify-between">
        <span class="text_10">are&nbsp;Chinese</span>
        <span class="text_11">&nbsp;are&nbsp;international</span>
      </div>
      <div class="box_6 flex-col">
        <div class="group_5 flex-col"></div>
      </div>
    </div>
    <div class="box_7 flex-col">
      <span class="text_12">STUDENT&nbsp;STORIES</span>
      <div class="text-wrapper_5 flex-col">
        <span class="text_13">
                Here are a few examples of how NYU Shanghai students partner with faculty to carry out cutting-edge research:
        </span>
      </div>
    </div>
    <div class="box_10EN flex-col">
      <div class=" list_2 flex-row" style="justify-content: space-around;">
        <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===1}  " @click="handleShow(1)"
                  icon-class="page3stu (1)"></svg-icon>
        <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===2}  " @click="handleShow(2)"
                  icon-class="page3stu (2)"></svg-icon>
        <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===3}  " @click="handleShow(3)"
                  icon-class="page3stu (1)"></svg-icon>
      </div>
      <div style="font-size: 3vw" v-show="currnetStu  === 1">
        <!--        <p class="text_58">张珺安</p>-->
        <div class="text_59" style="  line-height: 4.5vw;  padding: 5vw  2vw;">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Under the mentorship of Professor Keith Ross, Computer
          Science major Alexandru Grigoras laid the groundwork for a
          virtual assistant software that interacts with other software
          using emulated human actions and gestures, rather than relying
          on specific software integration, to perform any technological
          task a human being can achieve using software.
        </div>
      </div>
      <div style="font-size: 3vw" v-show="currnetStu  === 2">
        <!--        <p class="text_58">李慧琳</p>-->
        <div class="text_59" style="    padding: 5vw  2vw;line-height: 4.5vw">

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; With the help of Professor Pekka Santtila, Suncica Bruck, a
          Social Science major, conducted meta-analysis to determine
          how support from a service dog can improve various areas
          of difficulty associated with autism spectrum disorder (ASD),
          including impairments in social interaction and communication,
          limiting stereotypical behaviors, and the stress and anxiety that
          the families of children who have ASD experience.
        </div>
      </div>
      <div style="font-size: 3vw" v-show="currnetStu  === 3">
        <!--        <p class="text_58">徐瑜</p>-->
        <div class="text_59" style="    padding: 5vw 2vw;line-height: 4.5vw">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Business and Finance major Wanning Fu, mentored by Professor
          Tatiana Linkhoeva, conducted a study showing how Japan
          implemented both Western and Chinese ideas during the Meiji
          Restoration to modernize its society, abolish the old ruling class,
          and turn Japan into a rich nation with a strong army.
        </div>
      </div>

    </div>
    <myfooter :eng="true"></myfooter>
  </div>
</template>
<script>


export default {
  data() {
    return {
      activeNamesEl: ['1'],
      currnetStu: 1,
      activeNames: [ 2,3],
      swiperOption: {
        on: {
          slideChangeTransitionEnd: function (e) {
            console.log(e)
            // console.log(this.activeIndex)
            // 切换结束时，告诉我现在是第几个slide
          }
        }
      },
      swiperIndex: 0,
      dataItem: [],
      dataImgItem: [
        {
          imgSrc: require('./assets/img/page4Teacher1.png'),
          tag: '物理学与数学',
          name: 'Jun Zhang',
          link: 'https://shanghai.nyu.edu/academics/faculty/directory/jun-zhang'
        }, {
          imgSrc: require('./assets/img/page4Teacher2.png'),
          tag: '数学',
          name: 'Gérard Ben Arous',
          link: 'https://cims.nyu.edu/~benarous/'
        }, {
          imgSrc: require('./assets/img/page4Teacher3.png'),
          tag: '物理学',
          name: 'Daniel Stein',
          link: 'https://math.nyu.edu/people/profiles/STEIN_Daniel.html'

        }, {
          imgSrc: require('./assets/img/page4Teacher4.png'),
          tag: '经济学与商学（诺贝尔经济学奖得主）',
          name: 'Thomas Sargent',
          link: 'https://as.nyu.edu/faculty/thomas-j-sargent.html'

        }, {
          imgSrc: require('./assets/img/page4Teacher5.png'),
          tag: '数学与神经科学',
          name: 'David McLaughlin',
          link: 'https://shanghai.nyu.edu/cn/shizi/david-mclaughlin'

        }, {
          imgSrc: require('./assets/img/page4Teacher6.png'),
          tag: '数学',
          name: 'Charles Newman',
          link: 'https://shanghai.nyu.edu/cn/shizi/charles-newman'

        }, {
          imgSrc: require('./assets/img/page4Teacher7.png'),
          tag: '经济学（诺贝尔经济学奖得主）',
          name: 'Robert Engle',
          link: 'https://www.stern.nyu.edu/faculty/bio/robert-engle'
        }, {
          imgSrc: require('./assets/img/page4Teacher11.png'),
          tag: '神经科学与心理学',
          name: 'Anthony Movshon',
          link: 'https://as.nyu.edu/faculty/j-anthony-movshon.html'
        }, {
          imgSrc: require('./assets/img/page4Teacher12.png'),
          tag: '计算机科学、数据科学',
          name: 'Keith Ross',
          link: 'https://shanghai.nyu.edu/cn/academics/faculty/directory/keith-ross'

        }, {
          imgSrc: require('./assets/img/page4Teacher13.png'),
          tag: '数学',
          name: 'Fanghua Lin',
          link: 'https://research.shanghai.nyu.edu/centers-and-institutes/math/people/fanghua-lin'

        },
      ],
      newCurrentPage: 1
    };
  },
  mounted() {
    this.$emit('changeView')
  },
  methods: {
    handleShow(e) {
      this.currnetStu = e
    },
    slideChangeEnd(page) {
      console.log(page);
    },
    handleChangeSwiper() {
      this.swiperIndex = this.$refs.mySwiper.swiper.activeIndex
    },
    slideMove(page) {
      console.log(page);
    },
    slideChangeStart(page) {
      console.log(page);
    },
    add() {
      this.newCurrentPage = this.newCurrentPage + 1;
    },
    sub() {
      this.newCurrentPage = this.newCurrentPage - 1;
    }
  }
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss">
#app {
  height: 100%
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

</style>
