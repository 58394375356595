<template>
  <div v-if=" $i18n.locale === 'ZH'" class="page3 flex-col">
    <div class="box_1 flex-row">
      <div class="group_1 flex-col"></div>
      <span class="text_1"></span>
      <div class="group_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2"></span>
        <span class="text_3"></span>
        <span class="text_4"></span>
      </div>
    </div>
    <div class="box_2 flex-col">
      <div class="section_1 flex-col">
        <div class="box_3 flex-col"></div>
        <div class="box_4 flex-col">
          <div class="box_5 flex-col">
            <div class="block_1 flex-col"></div>
            <span class="paragraph_1">
            <div style="width:  50vw">
              博雅教育与专业教育相
            </div>
              <div>
              结合的特色本科教育
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-wrapper_2 flex-col">
      <span class="text_5">
        “在人工智能大量运用的时代，人类将更多地投入到那些非标准化的、机器无法完成的工作当中。要让大学生具备面向未来的长期竞争力，大学教育应当重点培养学生的五项重要素质：算法思维、思辨能力、创造力、社会感知力、说服力。”
      </span>
      <span class="paragraph_2">
        ——雷蒙
        <br/>
        &nbsp;&nbsp;&nbsp;上海纽约大学常务副校长
      </span>
      <span class="text_6">
       上海纽约大学的本科教育秉承博雅教育的理念，将通识培养和专业训练的优势相结合，培养学生成为既有扎实专业基本功，又有很强跨专业知识迁移能力的一专多能人才。学校具有前瞻性的课程体系和自由、灵活的选课机制，使学生可在扎实的培养基础上，根据个人特长、志趣和能力，自主定制专业知识与技能模块，形成具有个人特色的发展路径和独一无二的核心竞争力。
      </span>
    </div>
    <div class="box_12 flex-col">
<!--      <div class="text-wrapper_18 flex-row justify-between">-->
<!--        <span class="text_7">充分探索学业兴趣</span>-->
<!--        <span class="text_8">/专业选择不受限制</span>-->
<!--      </div>-->
      <div class="middleTitle">
        <span class="text_6">充分探索学业兴趣</span>
        <span class="text_7">/专业选择不受限制</span>
      </div>
    </div>
    <div class="box_7 flex-col">
      <span class="text_9">
        所有学生在入学前无需确定专业，将在核心课程的框架下充分了解专业内容与行业前景，最早可在大一结束前、最晚在大二结束前自主确定专业方向。专业选择确定不存在席位竞争，真正零限制，专业课程开课零门槛。学生完全根据自身兴趣及发展愿景，运用双专业或辅修专业的组合，个性化定制个人学术路径。
      </span>
      <div class="block_2 flex-row justify-between">
        <span class="text_10">2022届中国毕业生修读第二专业或辅修专业</span>
        <div class="section_2 flex-col"></div>
      </div>
    </div>
    <div class="box_13 flex-col">
<!--      <a href="https://shanghai.nyu.edu/cn/curriculum/core-curriculum">-->
        <div class="middleTitle">
          <span class="text_6">核心课程</span>
          <span class="text_7 eng">/Core&nbsp;courses</span>
        </div>
<!--      </a>-->
      <a href="https://shanghai.nyu.edu/cn/curriculum/core-curriculum">
        <div style="margin-top: 3vw; font-size: 3.5vw; display: flex;justify-content: center;align-items: center ; color: rgb(174, 172, 173) " >
          <div>
            点击前往官网了解更多
          </div>
        </div>
      </a>

      <div class="text-wrapper_5 flex-col">
        <span class="text_13">核心课程六大模块</span>
        <span class="text_14">
          上海纽约大学的学生通过完成涵盖不同学科领域的博雅教育核心课程，深入了解不同学科内容及自身兴趣后，最早可在大一结束前、最晚在大二结束前自由确定专业方向。
        </span>
      </div>
      <span class="text_15">2022-2023学年通识教育核心课程各模块要求概览</span>
      <div class="group_3 flex-row justify-between" style="height: 30vw;    background-size: 96.8vw 34vw;">
        <span class="paragraph_3" style="margin-top: 7.14vw">
          社会和文化
          <br/>
          基础模块
        </span>
        <div class="text-group_5 flex-col">
          <span class="text_16">·《全球视野下的社会》课程（4学分）</span>
          <span class="text_17">·《人文视角》课程（4学分）</span>
          <span class="text_16">
            · 跨学科视角下的中国类课程（共8
            <br/>
            &nbsp;&nbsp;&nbsp;学分；2门课）
          </span>
        </div>
      </div>
      <div class="group_4 flex-row justify-between" style="padding-top: 6.6vw">
        <span class="text_18" style="margin-top: 4vw" >
               写作模块
        </span>
        <div class="text-group_6 flex-col" style="min-width: 56.6vw">
          <span class="text_19">·《探究性写作》课程（4学分）</span>
          <span class="text_20">·《人文视角》课程（4学分）</span>
        </div>
      </div>
      <div class="group_3 flex-row justify-between">
        <span class="paragraph_3" style=" margin-left: 2vw;   margin-top: 8.8vw; text-align: left">
          数学模块
        </span>
        <div class="text-group_5 flex-col" style=" min-width: 56.6vw">
          <span class="text_16"> &nbsp;</span>
          <span class="text_17">     ·数学类课程（4学分）  </span>
          <span class="text_16">

             &nbsp;
            <br/>
            &nbsp;&nbsp;&nbsp;
          </span>
        </div>
      </div>
      <div class="group_4 flex-row justify-between">
        <span class="text_18" style="    margin-top: 1.8vw;">
          科学模块
        </span>
        <div class="text-group_6 flex-col" style="margin-top: -2vw ; min-width: 56.6vw">
          <span class="text_19">· 自然界的实验发现类课程（4学分）</span>
          <span class="text_19" >· 科学、技术和社会类课程（4学分）</span>
        </div>
      </div>
      <div class="group_3 flex-row justify-between">
        <span class="paragraph_3" style="margin-left: -1vw;    margin-top: 9.14vw;">
            算法思维模块
        </span>
        <div class="text-group_5 flex-col" style="min-width: 56.6vw">
          <span class="text_16">&nbsp;</span>
          <span class="text_17">· 算法思维类课程（4学分）</span>
          <span class="text_16">
           &nbsp;
            <br/>
      &nbsp;
          </span>
        </div>
      </div>
      <div class="group_4 flex-row justify-between" style="padding-right: 11vw">
        <span class="text_18" style="margin-top: 1vw">
     语言模块
        </span>
        <div class="text-group_6 flex-col" style="margin-top: -2vw">
          <span class="text_19">·《学术英语》课程（共8学分；2个学期）</span>
          <span class="text_20">      &nbsp;</span>
        </div>
      </div>
      <div class="middleTitle">
        <span class="text_6">本科专业及方向</span>
        <span class="text_7 eng">/Major and Direction</span>
      </div>
      <a href="https://shanghai.nyu.edu/cn/academics/majors">
        <div style="margin-top: 3vw; font-size: 3.5vw; display: flex;justify-content: center;align-items: center ; color: rgb(174, 172, 173) " >
          <div>
            点击前往官网了解更多
          </div>
        </div>
      </a>
      <div>
        <nut-collapse style="width: 95vw" v-model="activeNames">
          <nut-collapse-item title="本科专业及方向" :name="1">
            <div class="block_5 flex-row justify-between">
              <div class="group_11 flex-col"></div>
              <div class="section_5 flex-col">
                <div class="text-wrapper_9">
                  <span class="text_31">电子信息工程</span>
                  <span class="text_32">（电子工程）</span>
                </div>
                <span class="text_33">化学</span>
                <span class="text_34">经济学</span>
                <div class="text-wrapper_10">
                  <span class="text_35">金融学</span>
                  <span class="text_36">（商学与金融、商学与市场营销）</span>
                </div>
                <div class="text-wrapper_11">
                  <span class="text_37">计算机科学与技术</span>
                  <span class="text_38">（计算机科学、计算机工程）</span>
                </div>
                <span class="text_39">神经科学</span>
                <div class="text-wrapper_12">
                  <span class="text_40">数据科学与大数据技术</span>
                  <span class="text_41">（数据科学）</span>
                </div>
                <div class="text-wrapper_13">
                  <span class="text_42">世界史</span>
                  <span class="text_43">（全球中国学、综合人文、社会科学）</span>
                </div>
                <span class="text_44">生物科学</span>
                <div class="text-wrapper_14">
                  <span class="text_45">数学与应用数学</span>
                  <span class="text_46">（数学、荣誉数学）</span>
                </div>
                <div class="text-wrapper_15">
                  <span class="text_47">数字媒体技术</span>
                  <span class="text_48">（交互媒体艺术、交互媒体与商学）</span>
                </div>
                <span class="text_49">物理学</span>
              </div>
            </div>

          </nut-collapse-item>
          <nut-collapse-item title="上海辅修专业" :name="2">
            <div class="block_5 flex-row justify-between">
              <div class="group_11 flex-col" style="height: 50vw"></div>
              <div class="section_5 flex-col">
                <div class="text-wrapper_9">
                  <span class="text_31">创意写作</span>
                  <span class="text_32"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">分子和细胞生物学</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">基因组学和生物信息学</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">历史学</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">商学</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">中文</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">哲学</span>
                  <span class="text_41"></span>
                </div>
              </div>
            </div>
          </nut-collapse-item>
          <div  >


          </div>
          <nut-collapse-item title="    <div>
              全球辅修专业
            </div>
            <div style='font-size: 3vw ; margin-top: 1vw;color: grey'>
          海外学习期间在当地可选修
      </div> " :name="3">
            <div class="block_5 flex-row justify-between">
              <div>
                <div class="group_11 flex-col long"></div>
                <div class="group_11 flex-col long" style="margin-top: 1vw"></div>
                <div class="group_11 flex-col long" style="height: 35vw"></div>

              </div>
              <div class="section_5 flex-col">
                <div class="text-wrapper_12">
                  <span class="text_40">澳大利亚文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">创业与创新</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">德国文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">泛非文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">法国文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">可持续发展研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">拉丁美洲文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">欧洲文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">全球城市研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">全球公共卫生研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">全球化研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">时尚研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">全球公共卫生研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">摄影研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">文化研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">西班牙文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">西非文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">心理学研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">新闻学</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">意大利文化与研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">英国文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">艺术历史研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">艺术研究</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">中东文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">中欧文化与社会</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">政治学</span>
                  <span class="text_41"></span>
                </div>
              </div>
            </div>
          </nut-collapse-item>
          <nut-collapse-item title="法学和医学预科课程" :name="4">
            <div class="myText" style="color: black;    font-size: 3.46vw;">
              如有志于研究生阶段就读美国法学、医学领域研究生学位项目，可在本科阶段修习上海纽约大学法学预科、医学预科相关课程。总体而言，美国法学、医学领域研究生项目的招生对本科主修专业不设限制
            </div>
          </nut-collapse-item>
        </nut-collapse>
      </div>
<!--      <div class="text-wrapper_21 flex-row justify-between">-->
<!--        <span class="text_54"></span>-->
<!--        <span class="text_55">/STUDENT&nbsp;STORIES</span>-->
<!--      </div>-->
      <div class="middleTitle">
        <span class="text_6">学生故事</span>
        <span class="text_7 eng">/STUDENT&nbsp;STORIES</span>
      </div>
    </div>

    <div class="box_10 flex-col">

      <div class=" list_2 flex-row">
        <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===1}  " @click="handleShow(1)"
                  icon-class="page3stu (1)"></svg-icon>
        <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===2}  " @click="handleShow(2)"
                  icon-class="page3stu (2)"></svg-icon>
        <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===3}  " @click="handleShow(3)"
                  icon-class="page3stu (1)"></svg-icon>
      </div>
      <div v-show="currnetStu  === 1">
        <p class="text_58">张珺安</p>
        <div class="text_59">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;因对国际政治怀有浓厚兴趣，一入校，张珺安便选择了世界史（社会科学）专业，并专注于政治科学方向。虽有明确专业方向，但根据学校通识核心课程设置的要求，张珺安还修读了交互媒体艺术专业的课程，拓展了他对社科议题的研究视角。他大四的毕业论文关注传播学与政治学的交叉领域，并同时获得交互媒体艺术教授和政治科学教授的指导。张珺安入选了“第五届苏世民学者”项目，获得全奖前往清华大学苏世民书院攻读全球事务硕士。毕业后，入选香港太古集团的全球管理培训生项目，目前在旗下国泰航空的策划部任职。
        </div>
      </div>
      <div v-show="currnetStu  === 2">
        <p class="text_58">李慧琳</p>
        <div class="text_59">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          刚入学时，李慧琳决定主修金融学专业。但大一暑假起的实习经历，让她认识到数学在金融领域的广泛应用。大二上学期，她修读了两门数学课，并在教授鼓励下转学数学专业。大三这一年，李慧琳加入纽约大学柯朗数学科学研究所应用数学实验室，跟随教授进行流体力学相关研究，并获得上海纽约大学本科生科研基金（DURF）支持她在纽约完成项目主体部分。李慧琳收获了14所全球名校的博士和硕士项目录取通知书，目前正在攻读上海纽约大学与纽约大学文理研究生院及柯朗数学科学研究所联合培养的数学博士项目。
        </div>
      </div>
      <div v-show="currnetStu  === 3">
        <p class="text_58">徐瑜</p>
        <div class="text_59">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          徐瑜在大一下学期确定了金融学专业，但因为对人工智能、大数据、云计算、区块链等新兴技术领域深感兴趣，还选择了数据科学为第二专业。徐瑜大三时前往纽约大学斯特恩商学院进行海外学习，利用学院在金融科技方面的领先优势与创新资源继续进行跨学科探索。大四回到上海，她根据个人学业和发展兴趣制定自己的课表，修读了更高阶的计算机编程、机器学习以及数学课。毕业后，徐瑜前往瑞银集团（UBS）新加坡办公室工作，目前担任亚太区私募基金投资组合分析师。
        </div>
      </div>

    </div>
    <myfooter></myfooter>
  </div>
  <div v-else class="page3 flex-col eng">
    <div class="box_1 flex-row">
      <div class="group_1 flex-col"></div>
      <span class="text_1"></span>
      <div class="group_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2"></span>
        <span class="text_3"></span>
        <span class="text_4"></span>
      </div>
    </div>
    <div class="box_2 flex-col">
      <div class="section_1 flex-col">
        <div class="box_3 flex-col"></div>
        <div class="box_4 flex-col">
          <div class="box_5 flex-col">
            <div class="block_1 flex-col"></div>
            <span class="paragraph_1">
            <div style="width:  82vw  ;font-size: 5vw">
           WHERE GREAT MINDS
            </div>
              <div style="font-size: 5vw">
              CONVENE
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>



    <div class="box_13 flex-col" style="margin-top: 42.6vw ;">
      <div class="mycard" style="    height: 38vw; display: flex;justify-content: center;align-items: center;padding: 2vw">
        <div style="font-size: 3.2vw ;        width: 73vw;">
          Whether your academic interests lie in the humanities, sciences, or business, studying in Shanghai under the guidance of world-class faculty, and among Chinese and international classmates, will equip you with the tools to thrive as global citizens.
        </div>
      </div>
      <div class="middleTitle">
        <span class="text_6">DELVE INTO THE CORE</span>
      </div>

      <a href="https://shanghai.nyu.edu/curriculum/core-curriculum">
        <div style="margin-top: 3vw; font-size: 4vw; display: flex;justify-content: center;align-items: center ; color: rgb(174, 172, 173) " >
          <div style="font-size: 4vw" >
            Get More Info
          </div>
        </div>
      </a>

      <div class="text-wrapper_5 flex-col">
        <span class="text_14">
               NYU Shanghai’s Core Curriculum has six components that give you a strong foundation in the liberal arts and sciences. They are:

   </span>
      </div>
<!--      <div class="group_3 flex-row justify-between myEN">-->
<!--        <div class="paragraph_3 myEN" style="">-->
<!--          <div>-->
<!--            Top-->
<!--          </div>-->
<!--          <div>-->
<!--            4-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="text-group_5 flex-col">-->
<!--          <span class="text_16">Majors in the Class of 2021</span>-->
<!--          <span class="text_17">·Business-->
<!--and Finance</span>-->
<!--          <span class="text_16">-->
<!--            · Computer-->
<!--Science-->
<!--            <br/>-->
<!--            ·  Interactive Media Arts-->
<!--            <br/>-->
<!--            ·   Interactive Media Arts-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
      <div class="section_3 enLan flex-col" style="margin-top: 5vw">
        <div class="text-wrapper_4">
          <div class="paragraph_3" style="font-size: 3.5vw">
            <div class="myline">
              · ALGORITHMIC THINKING
            </div>
            <div  class="myline">
              · LANGUAGE
            </div>
            <div  class="myline">
              · MATHEMATICS
            </div>
            <div  class="myline">
              · SCIENCE
            </div>
            <div  class="myline" style="width:78vw ">
              · SOCIAL ADN CULTURAL FOUNDATIONS
            </div>
            <div  class="myline">
              · WRITING
            </div>

          </div>
        </div>
      </div>
      <div class="group_16enLan" style="margin-top: 5vw">
        <div class="box_10"  style="display: flex; padding-right: 0 ;justify-content: space-between">
          <div class="paragraph_3 myEN" style="color: white; display: flex;flex-direction: column ;text-align: left; justify-content: center;align-items: center; font-size: 4vw;flex: 1 ;    margin-left: -10vw;">
            <div>
              Top
            </div>
            <div>
              4
            </div>
          </div>
          <div class="text-group_5 flex-col book" style="display: flex;flex: 2; flex-direction: column ;font-size: 3.6vw ;" >
            <span class="text_17" style="height: 5vw">Majors in the Class of 2021</span>
            <span class="text_17" style="height: 5vw;width: auto" >·Computer Science  &nbsp;  ·Social Science</span>

            <span class="text_17" style="height: 5vw">·Business and Finance</span>
            <span class="text_17" style="height: 5vw">·Interactive Media Arts</span>
          </div>
        </div>
      </div>
      <div class="group_16enLan" style="margin-top: 5vw">
        <div class="box_10" style="display: flex;justify-content: space-between">
          <div class="paragraph_3 myEN" style="color: white; display: flex;flex-direction: column ;text-align: left; justify-content: center;align-items: center; font-size: 4vw;flex: 1 ;    margin-left: -10vw;">
            <div>
              60%
            </div>
          </div>
          <div class="text-group_5 flex-col book" style="display: flex;flex: 2; flex-direction: column ;font-size: 3.5vw" >
            <span class="text_17" style="height: 3.5vw"></span>

            <span class="text_17" style="height: 3.5vw">of the Class of 2021 completed </span>
            <span class="text_17" style="height: 3.5vw"></span>
            <span class="text_17" style="height: 3.5vw">a second major or minor</span>
            <span class="text_17" style="height: 3.5vw"></span>
          </div>
        </div>
      </div>
      <div class="middleTitle">
        <span class="text_6">AREAS OF STUDY</span>
      </div>
      <a href="https://shanghai.nyu.edu/academics/majors">
        <div style="margin-top: 3vw; font-size: 4vw; display: flex;justify-content: center;align-items: center ; color: rgb(174, 172, 173) " >
          <div style="font-size: 4vw">
            Get More Info
          </div>
        </div>
      </a>
      <div class="text-wrapper_5 flex-col">
        <span class="text_14">
                    Once you have completed your core courses, you may declare a major from among 19 options, including a Self-Designed Honors Major.

        </span>
      </div>
      <div style="    margin-top: 5vw;">
        <nut-collapse style="width: 95vw" v-model="activeNames">
          <nut-collapse-item title="ACADEMIC MAJORS" :name="1">
            <div class="block_5 flex-row justify-between">
              <div class="group_11 flex-col" style="position: relative ;height: 187.94vw;">
                <div class="group_11 flex-col" style="position: absolute;top: 88vw;"></div>
              </div>
              <div class="section_5 flex-col" style="    margin: -4.2vw 0 0.53vw 0;">
                <div class="text-wrapper_10">
                  <span class="text_35">Biology</span>
<!--                  <span class="text_36">（商学与金融、商学与市场营销）</span>-->
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Business and Finance</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Business and Marketing</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Chemistry</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Computer Science</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Computer Systems Engineering</span>
                </div>

                <div class="text-wrapper_10">
                  <span class="text_35">Data Science</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Economics</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Electrical and Systems Engineering</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Global China Studies</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Honors Mathematics</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Humanities</span>
                  <span class="text_36">
                    (history, literature,philosophy)</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Interactive Media and Business</span>
                </div>
             <div class="text-wrapper_10">
                  <span class="text_35">Interactive Media Arts</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Mathematics</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Neural Science</span>
                </div>              <div class="text-wrapper_10">
                  <span class="text_35">Physics</span>
                </div>
                <div class="text-wrapper_10">
                  <span class="text_35">Self-Designed Honors Major</span>
                  <span class="text_36">
                 (merge</span>
                  <div class="text_36" style="color: rgb(87, 6, 140)">
                    courses from multiple departments</div>
                                    <div class="text_36"  style="color: rgb(87, 6, 140) ;margin-top: 2vw">
                                      to create your own academic plan)</div>
                </div>
                <div class="text-wrapper_10" style="margin-top: 15vw;">
                  <span class="text_35">Social Science</span>
                  <span class="text_36">
                 (anthropology,</span>
                  <div class="text_36" style="color: rgb(87, 6, 140)">
                    environmental studies, global</div>
                  <div class="text_36"  style="color: rgb(87, 6, 140) ;margin-top: 2vw">
                    health, international relations,</div>
                  <div class="text_36"  style="color: rgb(87, 6, 140) ;margin-top: 2vw">
                    political economy, political science,</div>
                  <div class="text_36"  style="color: rgb(87, 6, 140) ;margin-top: 2vw">
                    psychology, sociology, urban studies)</div>
                </div>
                <div class="text-wrapper_10">
<!--                                    <div class="text_36"  style="color: rgb(87, 6, 140) ;margin-top: 2vw">-->
<!--                                      to create your own academic plan)</div>-->
                </div>
<!--                <div class="text-wrapper_10">-->
<!--                  <span class="text_35"></span>-->

<!--                  <div class="text_36" style="color: rgb(87, 6, 140)">-->
<!--                    courses from multiple departments</div>-->
<!--                  <div class="text_36"  style="color: rgb(87, 6, 140)">-->
<!--                    to create your own academic plan)</div>-->
<!--                </div>-->
              </div>
            </div>

          </nut-collapse-item>
          <nut-collapse-item title="ACADEMIC MINORS" :name="2">
            <div class="block_5 flex-row justify-between">
              <div class="group_11 flex-col" style="height: 77vw"></div>
              <div class="section_5 flex-col">
                <div class="text-wrapper_9">
                  <span class="text_31"></span>
                  <span class="text_32"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Business</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Chinese</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Chinese Language and Literature</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Creative Writing</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Creativity and Innovation</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Genomics and Bioinformatics</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">History</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Literature</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Molecular and Cell Biology</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Philosophy</span>
                  <span class="text_41"></span>
                </div>
              </div>
            </div>
          </nut-collapse-item>
<!--          <div style='width: 80vw;text-align: center'>-->
<!--              GLOBAL NETWORK UNIVERSITY MINORS-->
<!--          </div>-->
          <nut-collapse-item title="           <div style='width: 80vw;text-align: center'>
              GLOBAL NETWORK UNIVERSITY MINORS
          </div> " :name="3">
            <div class="block_5 flex-row justify-between">
              <div>
                <div class="group_11 flex-col long"></div>
                <div class="group_11 flex-col long" style="margin-top: 1vw"></div>
                <div class="group_11 flex-col long" style="height: 15vw;"></div>

              </div>
              <div class="section_5 flex-col">
                <div class="text-wrapper_12">
                  <span class="text_40">Art History Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Art Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Australian Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">British Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Central European Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Cultural Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Entrepreneurship and Innovation</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">European Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Fashion Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">French Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">German Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Global Cities Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Global Public Health Studies </span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Globalization Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Italian Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Journalism Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Latin American Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Middle Eastern Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Pan African Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Photographic Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Political Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Psychological Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Sociological Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Spanish Culture and Society</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">Sustainability Studies</span>
                  <span class="text_41"></span>
                </div>
                <div class="text-wrapper_12">
                  <span class="text_40">West African Culture and Society</span>
                  <span class="text_41"></span>
                </div>
              </div>
            </div>
          </nut-collapse-item>
<!--          <div style="width: 80vw">-->
<!--                 <span>-->
<!--             A NOTE FOR STUDENTS-->
<!--            INTERESTED IN PREMED-->
<!--            AND PRELAW-->
<!--          </span>-->
<!--          </div>-->
<div></div>
<!--          <div style='width: 80vw;text-align: center;height: 30vw' >-->
<!--            A NOTE FOR STUDENTS-->
<!--            INTERESTED IN PREMED-->
<!--            AND PRELAW-->
<!--          </div>-->
          <nut-collapse-item title="      <div style='width: 80vw;text-align: center;' >
          <div>
              A NOTE FOR STUDENTS
            INTERESTED IN PREMED
            AND PRELAW
          </div>


          </div>" :name="4">

            <div class="myText" style="color: black;    font-size: 3.46vw;">
              NYU Shanghai provides preprofessional advising in prehealth and prelaw for
              students who wish to pursue further education in preparation for careers in
              health and legal professions.
            </div>
          </nut-collapse-item>
        </nut-collapse>
      </div>
<!--      <div class="text-wrapper_21 flex-row justify-between">-->
<!--        <span class="text_54"></span>-->
<!--        <span class="text_55">/STUDENT&nbsp;STORIES</span>-->
<!--      </div>-->
      <div class="middleTitle">
        <span class="text_6">STUDENT&nbsp;STORIES</span>
      </div>
    </div>

    <div class="box_10 flex-col">

      <div class=" list_2 flex-row">
        <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===1}  " @click="handleShow(1)"
                  icon-class="page3stu (1)"></svg-icon>
        <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===2}  " @click="handleShow(2)"
                  icon-class="page3stu (2)"></svg-icon>
        <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===3}  " @click="handleShow(3)"
                  icon-class="page3stu (1)"></svg-icon>
      </div>
      <div v-show="currnetStu  === 1">
<!--        <p class="text_58">张珺安</p>-->
        <div class="text_59" style="    padding: 5vw  2vw;">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; With support from the Deans’ Undergraduate Research Fund,
          Computer Science major Sevi Reyes worked with classmates
          to create a robotic arm to aid people with disabilities. He
          also studied at NYU London and New York City and interned
          at banks in each city, as well as in Shanghai, leading to a job
          opportunity at Morgan Stanley. </div>
      </div>
      <div v-show="currnetStu  === 2">
<!--        <p class="text_58">李慧琳</p>-->
        <div class="text_59" style="    padding: 5vw  2vw;">

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Eszter Vigh, an Interactive Media and Business major and
          Social Science minor with a concentration in global health,
          studied at NYU Accra in Ghana where she interned at a
          hospital and took a course called Mass Communications in
          Public Health and Health Education to further explore
          her interest in media and health care.   </div>
      </div>
      <div v-show="currnetStu  === 3">
<!--        <p class="text_58">徐瑜</p>-->
        <div class="text_59" style="    padding: 5vw 2vw;">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          Lauren Benedict came to NYU Shanghai planning to focus
          on the Business and Finance major, but after taking a course
          called The Concept of China and learning about the country
          from multiple perspectives, she was inspired to add a second
          major: Global China Studies.</div>
      </div>

    </div>
    <myfooter :eng="true"></myfooter>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNames: [1, 2],
      currnetStu: 1,
      loopData0: [
        {
          lanhuBg0:
              'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngb9c254d0f8b8d7d607d01c5a569c2881e96a5102de5021d26554d2acdeae537a) 100% no-repeat'
        },
        {
          lanhuBg0:
              'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngcd670c3d391db4eb1e0df754f0e8aa7160be45e85fdfcf5f6c9cd89c56982b1b) 100% no-repeat',
          slot1: 1
        },
        {
          lanhuBg0:
              'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng9e22d46cd4319f593fc52ca1a70977dc9fa9c246f85b60a279ef30be6c0bf811) 100% no-repeat'
        }
      ],
      constants: {}
    };
  },
  mounted() {
    this.$emit('changeView')
  },

  methods: {
    handleShow(e) {
      this.currnetStu = e
    }
  }
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss"/>
