import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2d3fbd08&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./assets/index.response.scss?vue&type=style&index=0&id=2d3fbd08&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d3fbd08",
  null
  
)

export default component.exports