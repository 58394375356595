import VueI18n   from 'vue-i18n';
// element-plus 中的语言配置
import Vue from 'vue';
// 自己的语言配置
// import enLocale from './en';
// import zhLocale from './zh-cn';
Vue.use(VueI18n);
// 语言配置整合
// const messages = {
//     'en': {
//         ...enLocale,
//     },
//     'zh': {
//         ...zhLocale,
//     },
// };


// 创建 i18n
// const i18n = createI18n({
//     legacy: true,
//     globalInjection: true,  // 全局模式，可以直接使用 $t
//     locale:  'zh',
//     messages: messages,
// });

// const i18n = new createI18n({
//     // 设置默认语言
//     locale: 'zh', // 语言标识
//     // 添加多语言（每一个语言标示对应一个语言文件）
//     legacy: false,
//     globalInjection: true,  // 全局模式，可以直接使用 $t
//     messages: {
//         zhLocale,
//         enLocale,
//     }
// })


const i18n = new VueI18n({
    legacy: false,
    locale: "ja",
    globalInjection: true,
    messages: {
        en: {
            message: {
                language: "English",
                greeting: "Hello !"
            }
        },
        ar: {
            message: {
                language: "العربية",
                greeting: "السلام عليكم"
            }
        },
        es: {
            message: {
                language: "Español",
                greeting: "Hola !"
            }
        }
    }
});


export default i18n;
