<template>
  <div class="pageshouye flex-col">
    <div class="nav-bar_1 flex-row">
      <div class="icon_1 flex-col"></div>
      <span class="text_1"></span>
      <div class=" flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2"></span>
        <span class="text_3"></span>
        <span class="text_4"></span>
      </div>
    </div>
    <div class="section_1 flex-col">
      <div class="block_2 flex-col" style="height: calc(100vh - 32.01vw)">
        <div class="block_3 flex-col eng">
          <span class="text_5">nyu</span>
          <div class="box_3 flex-col"></div>
          <span class="text_6">shanghai</span>
        </div>
        <div class="block_4 flex-col"></div>
      </div>
    </div>
    <div class="box_20 flex-col" style="margin-top: 2vw">
      <div class="group_12 flex-row">
        <div class="text-group_18 flex-col">
          <span v-if=" $i18n.locale === 'ZH'" class="text_8">特色本科教育  </span>
          <span v-else class="text_8 eng" style="margin-left: 0">Undergraduate   </span>
          <span v-if=" $i18n.locale === 'EN'" class="text_8 eng">Studies</span>
          <span v-if=" $i18n.locale === 'ZH'" class="text_9">通识教育和专业教育相结合</span>
          <span v-else class="text_9 eng">Core courses and majors</span>
        </div>
      </div>
      <div class="box_21 flex-row justify-between">
        <div class="group_14 flex-row">
          <div class="text-group_19 flex-col">
            <span v-if=" $i18n.locale === 'ZH'" class="text_11">师资队伍</span>
            <span v-else class="text_11 eng" style="margin-right: 0;text-align: right">Faculty</span>
            <span v-if=" $i18n.locale === 'ZH'" class="text_12">国际化、高水平、结构合理的师资队伍</span>
            <span v-else class="text_12 eng" style="height: auto;text-align: right">International&</span>
            <span v-if=" $i18n.locale === 'EN'" class="text_12 eng"
                  style="height: auto;text-align: right">World-class</span>
          </div>
        </div>
        <div class="group_15 flex-row" :style="{'paddingBottom' : $i18n.locale === 'EN' ? '4vw' : '' }">
          <div class="text-group_20 flex-col" style=" ">
            <span v-if=" $i18n.locale === 'ZH'" class="text_14">升学就业</span>
            <span v-else class="text_14 eng"
                  style="margin-right: 0;text-align: right ;    line-height: 4.8vw;">Graduate</span>
            <span v-if=" $i18n.locale === 'EN'" class="text_14 eng"
                  style="margin: 0;text-align: right ;line-height: 4.8vw;">Destinations</span>

            <span v-if=" $i18n.locale === 'ZH'" class="text_15">出色的学术能力与职业素养</span>
            <span v-else class="text_15 eng" style="height: auto;width: auto; line-height: 4.8vw; text-align: right">A step ahead on job</span>

            <span v-if=" $i18n.locale === 'EN'" class="text_15 eng"
                  style="height: auto; line-height: 4.8vw; text-align: right ;    margin-left: 3vw;">&ensp;&ensp;&ensp;&ensp;market</span>
          </div>
        </div>
      </div>
      <div class="text-wrapper_24 flex-row justify-between"
           :style="{'justifyContent' : $i18n.locale === 'EN' ? 'center' : '' }">
        <span v-if=" $i18n.locale === 'ZH'" class="text_16">用数字说话</span>
        <span v-else class="text_16 eng">HIGHLIGHTS</span>
        <span v-if=" $i18n.locale === 'ZH'" class="text_17 eng">/HIGHLIGHTS</span>
      </div>
    </div>
    <div class="section_8 flex-col">
      <div v-if=" $i18n.locale === 'ZH'" class="group_24 flex-row justify-between">
        <span class="text_18">双料学位起点高</span>
        <div class="section_9 flex-col"></div>
      </div>
      <span v-if=" $i18n.locale === 'ZH'" class="text_19">学游全球无国界</span>
      <div v-if=" $i18n.locale === 'ZH'" class="group_25 flex-row justify-between">
        <div class="section_10 flex-col"></div>
        <span class="text_20">专业发展不设限</span>
      </div>
      <div v-if=" $i18n.locale === 'EN'" style="margin-left: 0" class="group_24 flex-row justify-between">
        <span class="text_18 eng" style="font-size: 4vw">IMMERSE YOURSELF IN A NEW CULTURE</span>
        <div class="section_9 flex-col"></div>
      </div>
      <span v-if=" $i18n.locale === 'EN'" style="margin-left: 0 ;font-size: 4vw"
            class="text_19 eng">DARE TO EXPLORE</span>
      <div v-if=" $i18n.locale === 'EN'" style="margin-left: 0 ;font-size: 4vw"
           class="group_25 eng flex-row justify-between">
        <span class="text_20" style="margin-left: 0 ;font-size: 4vw">EMBRACE AN INTERNATIONAL MINDSET</span>
      </div>
      <div v-if=" $i18n.locale === 'ZH'" class="group_26 flex-row  justify-between" style="overflow-x: scroll">
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="编组 11备份 (1)"></svg-icon>
            <span class="text-group_1">2</span>
          </div>
          <span class="text_21">毕业生将获中美两张学位证书</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="pag1 (4)"></svg-icon>
            <span class="text-group_1" style="">1:8</span>
          </div>
          <span class="text_21">师生比将长期保持在1比8以内，保证小班化教学优势</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (5)"></svg-icon>
            <span class="text-group_1">20</span>
          </div>
          <span class="text_21">20人以下的小班课程，占全部课程的四分之三</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="topchange"></svg-icon>
            <span class="text-group_1">Top10</span>
          </div>
          <span class="text_21">纽约大学的数学、商学、哲学、神经科学、数据科学、法学、医学等多个学科均位列全球前十，学校综合排名稳定在世界前30位</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (8)"></svg-icon>
            <span class="text-group_1">3+12</span>
          </div>
          <span class="text_21">
          纽约大学全球体系的三大门户校园与12个海外学习中心，遍布全球五大洲
          </span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (9)"></svg-icon>
            <span class="text-group_1">50</span>
          </div>
          <span class="text_21">近20年来，纽约大学共产生5位诺贝尔奖得主、5位普利策奖得主、4位阿贝尔奖得主、7位麦克阿瑟奖得主，以及29位美国两院院士</span>
        </div>

        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (7)"></svg-icon>
            <span class="text-group_1">1800+</span>
          </div>
          <span class="text_21">
        在校本科生人数 </span>
        </div>
        <div class="box_7 flex-col" >
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (3)"></svg-icon>
            <span class="text-group_1">70+</span>
          </div>
          <span class="text_21">学生来自全球70多个国家和地区</span>
        </div>

      </div>
      <div v-else class="group_26 flex-row  justify-between" style="overflow-x: scroll">
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" >
            <svg-icon style="margin-top: -1.5vw" :svgStyle="{'width':'1.5em','height':'1.5em' }" icon-class="page1EN-1"></svg-icon>
            <span class="text-group_1" style="margin: 1.26vw 4vw 0 4.26vw"></span>
          </div>
          <span class="text_21 eng">NYU Shanghai is the first undergraduate
university in China to award both US and Chinese degrees</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="page1EN-2"></svg-icon>
            <span class="text-group_1" style="">28</span>
          </div>
          <span class="text_21 eng">provinces of China
represented</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="page1EN-3"></svg-icon>
            <span class="text-group_1" style="">40</span>
          </div>
          <span class="text_21 eng">40 US states represented</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="page1EN-4"></svg-icon>
            <span class="text-group_1" style="">50+</span>
          </div>
          <span class="text_21 eng">The number of languages known</span>
        </div>
        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col" style="">
            <svg-icon icon-class="pag1 (4)"></svg-icon>
            <span class="text-group_1" style="">6:1</span>
          </div>
          <span class="text_21 eng">student-faculty ratio</span>
        </div>

        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (8)"></svg-icon>
            <span class="text-group_1">29%</span>
          </div>
          <span class="text_21 eng">
           students are
from the United States
          </span>
        </div>

        <div class="box_7 flex-col">
          <div class="image-text_9 flex-col">
            <svg-icon icon-class="pag1 (3)"></svg-icon>
            <span class="text-group_1">60+</span>
          </div>
          <span class="text_21 eng">countries represented</span>
        </div>
      </div>
      <div @click="jumpToNum()" v-if=" $i18n.locale === 'ZH'" class="button_1 flex-col"
           style="margin: 6.4vw auto  0  auto;"><span class="text_26">点击查看更多</span></div>
      <div @click="jumpToNum()" v-else class="button_1 flex-col eng" style="margin: 6.4vw auto  0  auto;"><span
          class="text_26">Click to discover more</span></div>
    </div>
    <div v-if=" $i18n.locale === 'ZH'" class="box_25 flex-col">
      <div class="text-wrapper_26 flex-row justify-between">
        <span class="text_33">专业课程</span>
        <span class="text_34 eng">/course</span>
      </div>
      <div class="box_13 flex-col">
        <div class="group_7 flex-col"></div>
      </div>
      <div class="box_14 flex-row">
        <div class="text-group_25 flex-col">
          <span class="text_35">本科专业及方向</span>
          <span class="text_36">
            上海纽约大学实行通识教育核心课程的培养，设有19个本科专业及方向，提供多个跨学科的辅修专业课程。入学时不定专业，最晚大二下、最早大一下自由确定专业。
          </span>
        </div>
      </div>
      <div class="image-text_12 flex-row justify-between">
        <div class="group_8 flex-col"></div>
        <div class="text-group_26 flex-col">
          <div class="text-wrapper_7">
            <span class="text_37">电子信息工程</span>
            <span class="text_38">（电子工程）</span>
          </div>
          <span class="text_39">化学</span>
          <span class="text_40">经济学</span>
          <div class="text-wrapper_8">
            <span class="text_41">金融学</span>
            <span class="text_42">（商学与金融、商学与市场营销）</span>
          </div>
          <div class="text-wrapper_9">
            <span class="text_43">计算机科学与技术</span>
            <span class="text_44">（计算机科学、计算机工程）</span>
          </div>
          <span class="text_45">神经科学</span>
          <div class="text-wrapper_10">
            <span class="text_46">数据科学与大数据技术</span>
            <span class="text_47">（数据科学）</span>
          </div>
          <div class="text-wrapper_11">
            <span class="text_48">世界史</span>
            <span class="text_49">（全球中国学、综合人文、社会科学）</span>
          </div>
          <span class="text_50">生物科学</span>
          <div class="text-wrapper_12">
            <span class="text_51">数学与应用数学</span>
            <span class="text_52">（数学、荣誉数学）</span>
          </div>
          <div class="text-wrapper_13">
            <span class="text_53">数字媒体技术</span>
            <span class="text_54">（交互媒体艺术、交互媒体与商学）</span>
          </div>
          <span class="text_55">物理学</span>
        </div>
      </div>
      <div @click="jumpPage('/lanhu_3zhuanyekecheng')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin: 6vw auto 1px auto;">
        <span class="text_56">点击查看更多</span>
      </div>
      <div class="middleTitle" style="margin-bottom: 4vw;margin-top: 5vw">
        <span class="text_6">师资团队</span>
        <span class="text_7 eng">/FACULTY</span>
      </div>
      <div class="shouye6 flex-col">
        <div class="block_3 flex-row justify-between" style="overflow-y: scroll">
        <span class="paragraph_1">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;上海纽约大学按照纽约大学师资聘用标准面向全球招聘，已建成一支国际化、高水平、结构合理的师资队伍。引进的教师100%具有在国际一流高校执教或研究工作经验。师生比保持在1:8之内，处于世界领先水平。
          <br/>
          <br/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;办学至今，先后有26位各国院士级的世界学术领军人在校全时任职，为本科生直接授课。一流的师资和极致的小班化教学模式，为学生创造最优质高效的教学体验，实现最大化的学习成果。在海外学习期间，无论前往任何一处校园或学习中心，学生将继续享受纽约大学全球顶尖的师资力量。
        </span>
        </div>
        <div class="group_16 flex-row justify-between" style="overflow-x: scroll">
          <div class="box_7 flex-col">
            <div class="image-text_9 flex-col">
              <svg-icon icon-class="pag1 (3)"></svg-icon>
              <span class="text-group_1">29</span>
            </div>
            <span class="text_21">教授来自国家</span>
          </div>
          <div class="box_7 flex-col">
            <div class="image-text_9 flex-col">
              <svg-icon icon-class="page4 (3)"></svg-icon>
              <span class="text-group_1">256</span>
            </div>
            <span class="text_21">全职教师</span>
          </div>
          <div class="box_7 flex-col">
            <div class="image-text_9 flex-col" style="">
              <svg-icon icon-class="page4 (4)"></svg-icon>
              <span class="text-group_1" style="">30%</span>
            </div>
            <span class="text_21">
              教授入选国家级或省级高端人才项目
            </span>
          </div>
          <div class="box_7 flex-col">
            <div class="image-text_9 flex-col">
              <svg-icon icon-class="page4 (5)"></svg-icon>
              <span class="text-group_1">23</span>
            </div>
            <span class="text_21">
            外国院、会士学者，以及诺贝尔奖得主
            </span>
          </div>
          <div class="box_7 flex-col" >
            <div class="image-text_9 flex-col">
              <svg-icon icon-class="page4 (1)"></svg-icon>
              <span class="text-group_1">48</span>
            </div>
            <span class="text_21">
               覆盖学科领域
            </span>
          </div>
          <div style="width: 10vw">

          </div>
        </div>
      </div>
      <div @click="jumpPage('/lanhu_4shizituandui')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin: 4vw auto 5vw auto;">
        <span class="text_56">点击查看更多</span>
      </div>
      <div class="middleTitle">
        <span class="text_6">本科科研</span>
        <span class="text_7 eng" style="height: auto">/UNDERGEADUATE RESEARCH</span>
      </div>
      <div class="text-Block flex-col" style="margin-top: 3vw;">
        <span class="text_6">
          上海纽约大学高度重视培养本科生的科研创新能力，为他们提供接触参与前沿科学研究，与专家深度交流的机会，培育具有实践和创新能力的本科人才。学生可根据自身兴趣能力，与导师共同进行诸多科研创新项目的合作。每年都有学生在顶尖国际期刊或学术会议上发表论文。
        </span>
      </div>
      <div @click="jumpPage('/lanhu_5benkekeyan')" class="text-wrapper_14 flex-col"
           style="display: flex ;
              margin: 5vw auto 1px auto;">
        <span class="text_56">点击查看更多</span>
      </div>
      <div class="middleTitle" style="margin-top: 6vw">
        <span class="text_6">学业体系</span>
        <span class="text_7 eng">/ACADEMIC SYSTEM</span>
      </div>
      <div class="text-Block flex-col"
           style="display: flex;     margin-top: 2vw;    height: 47vw; justify-content: center;align-items: center">
        <div class="text_6" style="height: auto">
          从一入学，上海纽约大学的学生就将获得全面、细致、专业、多样的学术支持资源与服务，帮助他们提高学术水平、学业规划能力以及自主学习能力。
        </div>
      </div>
      <div @click="jumpPage('/lanhu_6xueyetixi')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin:2vw auto 1px auto;">
        <span class="text_56">点击查看更多</span>
      </div>
      <div class="middleTitle" style="margin-top: 6vw">
        <span class="text_6">毕业去向</span>
        <span class="text_7 eng">/GRADUATE DESTINATIONS</span>
      </div>

      <div class="block_20Biye flex-col">
      <span class="text_87"
      >上海纽约大学的特色本科教育，不仅让学生在升学或就业上深受肯定，更将持续助推他们的长期发展。截止目前，已公布五届本科毕业生就业质量报告。每届本科毕业生的成绩斐然，他们出色的学术能力与职业素养，获得社会各界广泛认可。</span
      >
        <div class="image-text_34 flex-row justify-between">
          <img
              class="image_2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng68561f5cd56b89e49adaa1b337d03945779d59129f5716b54249402f21a7fa67.png"
          />
          <span class="text-group_33">五届毕业生完整就业质量报告</span>
        </div>
      </div>
<!--      <div class="group_2 flex-row" style="margin-top: 2vw">-->
<!--        <div class="block_20 flex-col">-->
<!--      <span class="text_87"-->
<!--      >上海纽约大学的特色本科教育，不仅让学生在升学或就业上深受肯定，更将持续助推他们的长期发展。截止目前，已公布五届本科毕业生就业质量报告。每届本科毕业生的成绩斐然，他们出色的学术能力与职业素养，获得社会各界广泛认可。</span-->
<!--      >-->
<!--          <div class="image-text_34 flex-row justify-between">-->
<!--            <img-->
<!--                class="image_2"-->
<!--                referrerpolicy="no-referrer"-->
<!--                src="./assets/img/SketchPng68561f5cd56b89e49adaa1b337d03945779d59129f5716b54249402f21a7fa67.png"-->
<!--            />-->
<!--            <span class="text-group_33">五届毕业生完整就业质量报告</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      <span class="paragraph_2">-->
<!--        上海纽约大学的特色本科-->
<!--        <br/>-->
<!--        教育，不仅让学生在升学-->
<!--        <br/>-->
<!--        或就业上深受肯定，更将-->
<!--        <br/>-->
<!--        持续助推他们的长期发-->
<!--        <br/>-->
<!--        展。截止目前，已公布五-->
<!--        <br/>-->
<!--        届本科毕业生就业质量报-->
<!--        <br/>-->
<!--        告。每届本科毕业生的成-->
<!--        <br/>-->
<!--        绩斐然，他们出色的学术-->
<!--        <br/>-->
<!--        能力与职业素养，获得社会各界广泛认可。-->
<!--      </span>-->
<!--        <span class="text_5">五届毕业生完整就业质量报告</span>-->
<!--        <img-->
<!--            class="image_1"-->
<!--            referrerpolicy="no-referrer"-->
<!--            src="./assets/img/SketchPng68561f5cd56b89e49adaa1b337d03945779d59129f5716b54249402f21a7fa67.png"-->
<!--        />-->
<!--      </div>-->
      <div @click="jumpPage('/lanhu_7biyequxiang')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin:6vw auto 1px auto;">
        <span class="text_56">点击查看更多</span>
      </div>
      <div class="box_22 flex-col">
        <div class="text-wrapper_25 flex-row justify-between">
          <span class="text_27">全球教学体系</span>
          <span class="text_28 eng">/NYU&nbsp;GLOBAL&nbsp;NETWORK</span>
        </div>
      </div>
      <div @click="jumpToquanqiu()" class="section_13 flex-col">
        <div class="box_23 flex-row">
          <div class="group_4 flex-col"></div>
          <div class="text-group_23 flex-col">
            <span class="text_29">3</span>
            <span class="text_30">门户校园</span>
          </div>
          <div class="group_5 flex-col"></div>
          <div class="text-group_24 flex-col">
            <span class="text_31">12</span>
            <span class="text_32">海外学习中心</span>
          </div>
        </div>
        <div class="box_24 flex-row">
          <div class="group_6 flex-col"></div>
        </div>
      </div>
      <div @click="jumpToquanqiu()"  class="text-wrapper_14 flex-col"
           style="display: flex ;    margin: 6vw auto 1px auto;">
        <span class="text_56">点击查看更多</span>
      </div>
      <div class="middleTitle" style="margin-top:8vw">
        <span class="text_6">地区优势</span>
        <span class="text_7 eng">/LOCATIONS</span>
      </div>
      <div class="text-Block flex-col"
           style="display: flex;   margin-top: 5vw; height: 47vw; justify-content: center;align-items: center">
        <div class="text_6" style="height: auto">
          坐落于浦东陆家嘴金融贸易区核心地带，上海纽约大学秉承纽约大学“立足城市，融入城市”的理念，充分利用上海这座国际化大都市的经济、智力、文化和科技资源，为学生创造了独一无二的教学体验，接触来自全球的优秀人才，享受大量实习实践及工作机会。高度国际化的学生群体，让学生24小时沉浸在跨文化交流环境中。
        </div>
      </div>
      <div @click="jumpPage('/lanhu_9diquyoushi')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin:2vw auto 1px auto;">
        <span class="text_56">点击查看更多</span>
      </div>
      <div class="middleTitle" style="margin-top: 6vw">
        <span class="text_6">职业规划</span>
        <span class="text_7 eng">/CAREER DEVELOPMENT</span>
      </div>
      <div class="text-Block flex-col"
           style="display: flex; margin-top: 2vw;   height: 47vw; justify-content: center;align-items: center">
        <div class="text_6" style="height: auto">
          学校为学生提供全方位、精准化的职业发展辅导。职业发展辅导贯穿四年学习，让新生从开学第一天，便开始思考大学毕业乃至更长远的职业与人生规划。
        </div>
      </div>
      <div @click="jumpPage('/lanhu_10zhiyeguihua')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin: 3vw auto 1px auto;">
        <span class="text_56">点击查看更多</span>
      </div>
      <div class="text-wrapper_27 flex-row justify-between" style="width: auto">
        <span class="text_57">申请流程</span>
        <span class="text_58 eng">/Application PROCEDURE</span>
      </div>
      <div class="text-wrapper_16 flex-row justify-between">
        <span class="text_59">10月起</span>
        <span class="text_60">完成英语语言测试、在线申请并寄送纸质申请材料</span>
        <div class="box_18 flex-row justify-between">
          <span class="text_61">
            次年
            <br/>
            1月1日
          </span>
          <span class="text_62">申请截止</span>
          <div class="section_23 flex-row justify-between">
            <span class="paragraph_1">
              次年
              <br/>
              2月初
            </span>
            <span class="text_65">获知初审结果</span>
            <div class="group_21 flex-row justify-between">
              <div class="text-wrapper_22">
                <span class="text_66">次年</span>
                <span class="paragraph_2">
                  <br/>
                </span>
                <span class="text_67">2月或3月</span>
              </div>
              <span class="text_70">参加校园日活动</span>
              <div class="group_22 flex-row justify-between">
                <span class="paragraph_3">
                  次年
                  <br/>
                  春季
                </span>
                <span class="paragraph_4">
                  获知录取资格
                  <br/>
                  (预录取、待录取、淘汰)
                </span>
                <div class="group_23 flex-row justify-between">
                  <span class="paragraph_5">
                    次年
                    <br/>
                    6月
                  </span>
                  <span class="text_71">参加高考</span>
                  <div class="text-wrapper_23 flex-row justify-between">
                    <span class="paragraph_6">
                      次年
                      <br/>
                      7月
                    </span>
                    <span class="text_72">正式录取</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="box_25 flex-col eng" style="padding: 10.93vw 0 19.93vw 0;">
      <div class="text-wrapper_26 flex-row justify-between" style="display: flex;justify-content: center">
        <span class="text_33 eng">COURSE</span>
        <!--        <span class="text_34 eng">/course</span>-->
      </div>
      <div class="box_13 flex-col">
        <div class="group_7 flex-col"></div>
      </div>
      <div class="box_14 flex-row">
        <div class="text-group_25 flex-col">
          <span class="text_35 eng">ACADEMIC MAJORS</span>
          <!--          <span class="text_36">-->
          <!--            上海纽约大学实行通识教育核心课程的培养，设有19个本科专业及方向，提供多个跨学科的辅修专业课程。入学时不定专业，最晚大二下、最早大一下自由确定专业。-->
          <!--          </span>-->
        </div>
      </div>
      <div class="image-text_12 flex-row justify-between">
        <div class="group_8 flex-col" style="margin-right: 3vw;position: relative">
          <div class="group_8 flex-col" style="position: absolute;top: calc(100% + 1vw );"></div>
        </div>

        <div class="text-group_26 flex-col">
          <div class="text-wrapper_7">
            <span class="text_37 eng">Biology</span>
          </div>
          <span class="text_39 eng">Business and Finance</span>
          <div class="text-wrapper_8">
            <span class="text_41 eng">Business and Marketing</span>
          </div>
          <div class="text-wrapper_9">
            <span class="text_43 eng">Chemistry</span>
          </div>
          <span class="text_45 eng">Computer Science</span>
          <div class="text-wrapper_10">
            <span class="text_46 eng">Computer Systems Engineering</span>
          </div>
          <div class="text-wrapper_11">
            <span class="text_48 eng">Data Science</span>
          </div>
          <span class="text_50 eng">Economics</span>
          <div class="text-wrapper_12">
            <span class="text_51 eng">Electrical and Systems Engineering</span>
          </div>
          <div class="text-wrapper_13">
            <span class="text_53 eng">Global China Studies</span>
          </div>
          <span class="text_55 eng">Honors Mathematics</span>

          <div class="text-wrapper_13">
            <span class="text_53 eng">Humanities</span>
            <span class="text_54 eng">(history, literature,
philosophy)</span>
          </div>
          <span class="text_55 eng">Humanities</span>
          <span class="text_55 eng">Interactive Media and Business</span>
          <span class="text_55 eng">Interactive Media Arts</span>
          <span class="text_55 eng">Mathematics</span>
          <span class="text_55 eng">Neural Science</span>
          <span class="text_55 eng">Physics</span>
          <div class="text-wrapper_13">
            <span class="text_53 eng">Self-Designed Honors Major</span>
            <span class="text_54 eng"> (merge
              </span>
            <div class="text_54 eng">
              courses from multiple departments
            </div>
            <div class="text_54 eng" style="margin-top: 2vw">
              to create your own academic plan)
            </div>
          </div>
          <div class="text-wrapper_13" style="margin-top: 14vw">
            <span class="text_53 eng">Social Science </span>
            <span class="text_54 eng"> (anthropology,
              </span>
            <div class="text_54 eng">
              environmental studies, global
            </div>
            <div class="text_54 eng" style="margin-top: 2vw">
              health, international relations,
            </div>
            <div class="text_54 eng" style="margin-top: 2vw">
              political economy, political science,
            </div>
            <div class="text_54 eng" style="margin-top: 2vw">
              psychology, sociology, urban studies)
            </div>
          </div>
        </div>
      </div>
      <div @click="jumpPage('/lanhu_3zhuanyekecheng')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin: 32vw auto 1px auto;">
        <span class="text_56">Click to discover more</span>
      </div>
      <div class="middleTitle" style="margin-bottom: 4vw;margin-top: 5vw">
        <span class="text_6 eng">FACULTY</span>
      </div>
      <div class="shouye6 flex-col">
        <div class="block_3 flex-row justify-between" style="overflow-y: scroll">
        <span class="paragraph_1 eng">
          NYU Shanghai is the intellectual home of 333 of the world’s best teachers, scholars, researchers, and mentors. And because class sizes are small (the student-to-faculty ratio is 6-to-1), your professors are able to provide you with individualized academic guidance, advise you on research projects, help you connect with professionals in your field, and serve as career mentors in the future.
          <br/>
        </span>
        </div>
        <div class="group_16 flex-row justify-between"
             style="display: flex;justify-content: space-between ;      margin: 6.2vw -8.33vw 0px -2.74vw;  width: 90vw;">
          <div class="box_7 flex-col">
            <div class="image-text_9 flex-col">
              <svg-icon icon-class="pag1 (3)"></svg-icon>
              <span class="text-group_1">29</span>
            </div>
            <span class="text_21 eng" style="width: 41vw">nationalities represented</span>
          </div>
          <div class="box_7 flex-col">
            <div class="image-text_9 flex-col">
              <svg-icon icon-class="page4 (3)"></svg-icon>
              <span class="text-group_1">333</span>
            </div>
            <span class="text_21 eng" style="width: 41vw">faculty members</span>
          </div>

        </div>
        <div class="box_7 flex-col eng" style="display: flex;
        justify-content: space-around;
            margin-left: -3vw;
                width: 94vw;
                color: white;
        align-items: center;flex-direction: row;font-size: 3vw;">
          <div>
            <div>38%</div>
            <div>are Chinese</div>
          </div>

          <svg-icon :svgStyle="{'width': '10vw', 'height': '10vw' }" icon-class="pageshouyeEn99"></svg-icon>
          <div>
            <div>62%</div>
            <div> are international</div>
          </div>
        </div>
      </div>
      <div @click="jumpPage('/lanhu_4shizituandui')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin: 4vw auto 5vw auto;">
        <span class="text_56 eng">Click to discover more</span>
      </div>
      <div class="middleTitle">
        <span class="text_6 eng">UNDERGRADUATE RESEARCH</span>
      </div>
      <div class="text-Block flex-col"
           style="margin-top: 3vw;display: flex;height: 44vw; justify-content: center;align-items: center ">
        <div class="text_6 eng" style="    height: 12.8vw;">
          As a student at NYU Shanghai, you have the ability to do your own research and find solutions to today’s
          greatest challenges.
        </div>
      </div>
      <div @click="jumpPage('/lanhu_5benkekeyan')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin: 5vw auto 1px auto;">
        <span class="text_56 eng">Click to discover more</span>
      </div>
      <div class="middleTitle" style="margin-top: 6vw">
        <span class="text_6 eng">Chinese Learning</span>
      </div>
      <div class="text-Block flex-col long"
           style="display: flex;     margin-top: 2vw;    height: 47vw; justify-content: center;align-items: center">
        <div class="text_6 eng" style="height: auto">
          Language learning is an integral part of the NYU Shanghai experience. While English is the primary language of
          instruction, all students graduate proficient in Chinese language, and no previous knowledge of Chinese is
          required. Outside of the classroom, you’ll be immersed in China’s culture, offering no better way to learn the
          language.
        </div>
      </div>
      <div @click="jumpPage('/lanhu_6xueyetixi')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin:2vw auto 1px auto;">
        <span class="text_56 eng">Click to discover more</span>
      </div>
      <div class="middleTitle" style="margin-top: 6vw">
        <span class="text_6 eng">GRADUATE DESTINATIONS</span>
      </div>
      <div class="text-Block flex-col long2"
           style="display: flex;     margin-top: 2vw;    height: 82vw; justify-content: center;align-items: center">
        <div class="text_6 eng" style="height: auto">
          NYU SHANGHAI STUDENTS ARE A STEP AHEAD WHEN THEY ENTER THE
          JOB MARKET, thanks to NYU Shanghai’s focus on graduating students who
          are experts on China as well as in their field of study. While they are in school,
          the University’s Career Development Center (CDC) helps them find internships,
          develop professional-level skills, and connect with alumni in their field through
          the Alumni Executive Mentor Program. When they graduate, they will have
          access to both NYU and NYU Shanghai alumni around the world.
        </div>
      </div>

      <div @click="jumpPage('/lanhu_7biyequxiang')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin:6vw auto 1px auto;">
        <span class="text_56">Click to discover more</span>
      </div>
      <div class="middleTitle" style="margin-bottom: 4vw;margin-top: 5vw">
        <span class="text_6 eng">NYU&nbsp;GLOBAL&nbsp;NETWORK</span>
      </div>

      <div @click="jumpToquanqiu()" class="section_13 flex-col">
        <div class="box_23 flex-row" style="margin-top: -5vw;">
          <div class="text-group_23 flex-col" style="margin-top: -1vw; position: relative">
            <div style="position: absolute; font-size: 3vw;width: 50vw;color: white;top: 70vw;left: 18vw;">
              click to discover more
            </div>
            <div style="display: flex;align-items: center;     ">
              <div class="group_4 flex-col"></div>
              <div class="text_29" style="margin: 0 0vw 0 4.06vw;">3</div>
            </div>
            <div class="text_30" style="text-align: left">degree-granting</div>
            <div class="text_30" tyle="text-align: left"> campuses</div>
          </div>
          <div class="group_5 flex-col" style="margin-top:2vw">
            <div style="display: flex;align-items: center">
            </div>
            <div style="display: flex;flex-direction: column; margin-left: 15vw;">
              <div class="text_31" style="color: white">12
              </div>
              <div class="text_32" style="    height: 3.2vw;
    overflow-wrap: break-word;
    color: rgb(255, 255, 255);
    font-size: 3.2vw;
    text-align: center;
    white-space: nowrap;
        margin-left: -20vw;
    line-height: 4vw;
    margin-top: 1.6vw;">global academic centers
              </div>
              <div class="text_32" style="    height: 3.2vw;
    overflow-wrap: break-word;
    color: rgb(255, 255, 255);
    font-size: 3.2vw;
    text-align: center;
    white-space: nowrap;
        margin-left: -13vw;
    line-height: 4vw;
    margin-top: 1.6vw;"> and programs
              </div>
            </div>
          </div>

        </div>

        <div class="box_24 flex-row">
          <div class="group_6 flex-col"></div>
        </div>
      </div>
      <div class="middleTitle" style="margin-top:8vw">
        <span class="text_6">LOCATIONS</span>
      </div>
      <div class="text-Block flex-col"
           style="display: flex;   margin-top: 5vw; height: 47vw; justify-content: center;align-items: center">
        <div class="text_6" style="height: auto">
          The best of both worlds is yours at NYU Shanghai. You are part of a close-knit cohort but also part of the
          larger University, with full access to its state-of-the-art facilities and resources.
        </div>
      </div>
      <div @click="jumpPage('/lanhu_9diquyoushi')" class="text-wrapper_14 flex-col"
           style="display: flex ;    margin:2vw auto 1px auto;">
        <span class="text_56">Click to discover more</span>
      </div>
      <div class="middleTitle" style="margin-top: 6vw">
        <span class="text_6">APPLICATION PROCEDURE</span>
      </div>
      <div class="last">

        <div class="page1row">
          <div>Application Plan</div>
          <div>Application Deadline</div>

        </div>
        <div class="page1row">
          <div>Early Decision I</div>

          <div>November 1</div>
        </div>
        <div class="page1row">
          <div>Early Decision II</div>
          <div>January 1</div>

        </div>
        <div class="page1row">
          <div>Regular Decision</div>
          <div>January 5</div>

        </div>
      </div>
      <div class="last">

        <div class="page1row">
          <div>Application Plan</div>
          <div>Financial Aid Deadline*</div>

        </div>
        <div class="page1row">
          <div>Early Decision I</div>

          <div>November 15</div>
        </div>
        <div class="page1row">
          <div>Early Decision II</div>
          <div>January 15</div>

        </div>
        <div class="page1row">
          <div>Regular Decision</div>
          <div>February 20</div>

        </div>
      </div>
      <div class="last">

        <div class="page1row">
          <div>Application Plan</div>
          <div>Notification Date (approximate)</div>

        </div>
        <div class="page1row">
          <div>Early Decision I</div>

          <div>December 15</div>
        </div>
        <div class="page1row">
          <div>Early Decision II</div>
          <div>February 15</div>

        </div>
        <div class="page1row">
          <div>Regular Decision</div>
          <div>April 1</div>
        </div>
      </div>
    </div>
    <div v-if=" $i18n.locale === 'ZH'" @click="jumpPage('/lanhu_11shenqingliucheng')" class="text-wrapper_14 flex-col"
         style="display: flex ; margin: -8vw auto 20vw auto;">
      <span class="text_56">点击查看更多</span>
    </div>
    <div v-else @click="jumpPage('/lanhu_11shenqingliucheng')" class="text-wrapper_14 flex-col"
         style="display: flex ;   margin: -8vw auto 20vw auto;">
      <span class="text_56 eng">Click to discover more</span>
    </div>

    <myfooter v-if=" $i18n.locale === 'ZH'"></myfooter>
    <myfooter v-else :eng="true"></myfooter>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  computed: {
    myLan() {
      return this.i18n
    }
  },
  watch: {},
  methods: {
    myLanfun() {
      console.log(this.i18n)
      return this.i18n
    },
    jumpPage(e) {
      this.$emit('changeView')
      this.$router.push(e)
    },
    jumpToNum() {
      this.$emit('changeView')
      this.$router.push('/lanhu_2shuzishuohua')
      this.$emit('changeView')
    },
    jumpToquanqiu() {
      this.$emit('changeView')

      this.$router.push('/lanhu_88quanqiujiaoxuetixizhankai')
      this.$emit('changeView')
    },
    jumpTokecheng() {
      this.$emit('changeView')

      this.$router.push('/lanhu_3zhuanyekecheng')
      this.$emit('changeView')
    }
  }
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss"/>
