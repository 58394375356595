import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/font.css';
Vue.config.productionTip = false
import NutUI from '@nutui/nutui';
import '@nutui/nutui/dist/nutui.scss';
import './icons' // icon
NutUI.install(Vue);

import footer from '@/components/bottomLogo.vue'

import VueAwesomeSwiper from 'vue-awesome-swiper'
 import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'


Vue.component('myfooter',footer)
Vue.use(Viewer);
Viewer.setDefaults({
    'inline':false,//是否直接展示
    'button':true, //右上角按钮
    "navbar": false, //底部缩略图
    "title": false, //当前图片标题
    "toolbar": false, //底部工具栏
    "tooltip": false, //显示缩放百分比
    "movable": true, //是否可以移动
    "zoomable": true, //是否可以缩放
    "rotatable": false, //是否可旋转
    "scalable": false, //是否可翻转
    "transition": true, //使用 CSS3 过度
    "fullscreen": true, //播放时是否全屏
    "keyboard": false, //是否支持键盘
    debug: true,
    defaultOptions: {
        zIndex: 9999
    }
});


import { i18n } from './i18n/index' //国际化
 new Vue({
  router,
    i18n,
  render: h => h(App)
}).$mount('#app')
