<template>
  <div v-if=" $i18n.locale === 'ZH'" class="page12 flex-col">
    <div class="section_1 flex-row">
      <div class="box_1 flex-col"></div>
      <span class="text_1">导航</span>
      <div class="box_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="box_12 flex-col">
      <div class="box_1Change ">
                        <span class="paragraph_1CC"
                        >不想错过最新招生政策与动态？<br/>请留下您的联系方式<br/>我们将与您分享更多招生信息</span>
      </div>
      <!--      <div class="text-wrapper_22 flex-row justify-between">-->
      <!--        <span class="text_5"></span>-->
      <!--        <span class="text_6"></span>-->
      <!--      </div>-->
      <div class="middleTitle">
        <span class="text_6">联系方式</span>
        <span class="text_7 eng">/contact</span>
      </div>
    </div>
    <div class="box_13 flex-col">
      <div class="box_6 flex-col"></div>
      <div class="box_7 flex-col">
        <div class="group_3 flex-col"></div>
      </div>
<!--      <div class="text-wrapper_23 flex-row justify-between" style="height: auto">-->
<!--        <span class="text_7" style="height: 7.8vw">填写信息</span>-->
<!--        <span class="text_8 eng">/collect</span>-->
<!--      </div>-->
<!--      <div class="list_5 flex-col">-->
<!--        <div class="image-text_1 flex-row justify-between" v-for="(item, index) in loopData0" :key="index">-->
<!--          <div class="group_4 flex-col" :style="{ background: item.lanhuBg1 }"></div>-->
<!--          <span class="text-group_1" v-html="item.lanhutext0"></span>-->
<!--        </div>-->
<!--        <el-input v-model="name" class="info" type="text" placeholder="姓名">-->
<!--          <template #prepend>-->
<!--            <svg-icon icon-class="page12 (2)" icon-color="#722FA1FF"-->
<!--            ></svg-icon>-->
<!--          </template>-->
<!--        </el-input>-->
<!--        <el-input v-model="phone" class="info" type="text" placeholder="电话">-->
<!--          <template #prepend>-->
<!--            <svg-icon icon-class="page12 (1)" icon-color="#722FA1FF"-->
<!--            ></svg-icon>-->
<!--          </template>-->
<!--        </el-input>-->
<!--        <el-input v-model="email" class="info" type="text" placeholder="邮件">-->
<!--          <template #prepend>-->
<!--            <svg-icon icon-class="page12 (1)" icon-color="#722FA1FF"-->
<!--            ></svg-icon>-->
<!--          </template>-->
<!--        </el-input>-->

<!--      </div>-->
<!--      <div class="text-wrapper_4 flex-col"><span class="text_9">提交</span></div>-->
<!--      <span class="text_10">*上纽大致力于保护个人隐私，坚决杜绝数据滥用及过度打扰。</span>-->
    </div>
    <div class="section_6 flex-col" style="    top: 181.87vw; padding:  12.2vw 10.93vw 12.8vw 9.86vw">
      <div class="text-wrapper_26 flex-row justify-between">
        <span class="text_16">学校网址</span>
        <span class="text_17">www.shanghai.nyu.edu</span>
      </div>
      <div class="text-wrapper_26 flex-row justify-between">
        <span class="text_16">资讯热线</span>
        <span class="text_17">021-20595599</span>
      </div>
      <div class="text-wrapper_26 flex-row justify-between">
        <span class="text_16">资讯邮箱</span>
        <span class="text_17">shanghai.admissions&#64;nyu.edu&ensp;&ensp;&ensp;</span>
      </div>
      <div class="text-wrapper_26 flex-row justify-between">
        <span class="text_16">资讯现场</span>
        <span class="text_17">上海纽约大学本科招生办（新浪微博）</span>
      </div>
      <div class="text-wrapper_26 flex-row justify-between">
        <span class="text_16">官方微博</span>
        <span class="text_17">上海纽约大学本科招生办（新浪微博）</span>
      </div>
      <div class="text-wrapper_26 flex-row justify-between">
        <span class="text_16">官方微信</span>
        <span class="text_17">上海纽约大学招生办（NYUSHZS）</span>
      </div>
    </div>
    <div class="section_7 flex-col" style="    top: 259.2vw;">
      <div class="text-wrapper_29 flex-row justify-between">
        <span class="text_22">招生简章</span>
        <span class="text_23">官方微信</span>
      </div>
      <div class="group_7 flex-row justify-between">
        <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng214d6213b751124436958e7b731c1e831cbe832ea7fa97be398e8a78968bd117.png"
        />
        <div class="box_8 flex-col"></div>
        <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngce08da9f385587bdb73fa61499b21e3eb48e3acdf9ba79d2aacb45ddc8a8ddfe.png"
        />
      </div>
      <div class="group_6 flex-col"></div>
    </div>
    <div class="section_8 flex-col" style="width: 94vw;">
      <span class="text_24">上海纽约大学招生咨询QQ群</span>
      <div class="myrow12" style="position: relative">
        <div class="myrowLine12" style="height: 20vw">
          <div class="card12">
            <span class="text_25">华东1群</span>
            <div class="num12">111393813</div>
            <div class="location12">上海、浙江、江苏</div>
          </div>
          <div class="mid"></div>

          <div class="card12">
            <span class="text_25">东北群</span>
            <div class="num12">428414340</div>
            <div class="location12">黑龙江、吉林、辽宁</div>
          </div>
        </div>
        <div class="myrowLine12" style="height: 22vw;margin-top: -2vw">
          <div class="card12">
            <span class="text_25">华东2群</span>
            <div class="num12">458473522</div>
            <div class="location12">安徽、福建、江西、山东</div>
          </div>
          <div class="mid"></div>
          <div class="card12">
            <span class="text_25">华北群</span>
            <div class="num12">312415903</div>
            <div class="location12">北京、天津、河北、山西、内蒙古</div>
          </div>
        </div>
        <div class="myrowLine12">
          <div class="card12">
            <span class="text_25">西南西北群</span>
            <div class="num12">584188864</div>
            <div class="location12" style="width: 40vw;">重庆、四川、贵州、云南、西藏、陕西、甘肃、青海、宁夏、新疆</div>
          </div>

          <div class="card12">
            <span class="text_25">华中华南群</span>
            <div class="num12">605743020</div>
            <div class="location12" style="    width: 40vw;">河南、湖北、湖南、广东、广西、海南、港澳台</div>
          </div>
        </div>
        <div class="middle"
             style="position: absolute ;left: 48% ;height: 89% ;border-right: 1vw dotted #56068c  ;   top: 8vw;">
        </div>
      </div>
      <span class="text_32">注：请按照高考所在省、区、市加群，约3个工作日通过申请</span>
      <div class="block_9 flex-col"></div>
    </div>
    <myfooter></myfooter>
  </div>
  <div v-else class="page12EN eng flex-col">
    <div class="box_17 flex-col justify-end">
      <div class="box_18 flex-row">
        <div class="section_3 flex-col"></div>
        <span class="text_17">Menu</span>
        <div class="section_4 flex-col"></div>
        <div class="text-wrapper_4">
          <span class="text_18">EN</span>
          <span class="text_19"></span>
          <span class="text_20">|</span>
          <span class="text_21">中文</span>
        </div>
      </div>
      <div class="box_19 flex-col">
        <span class="text_7">contact</span>
        <div class="box_13 flex-row">
          <div class="box_6 flex-col"></div>
          <div class="text-wrapper_2" style="width: auto">
            <span class="text_6">Please&nbsp;leave&nbsp;your&nbsp;message</span>
            <span class="paragraph_1" style="width: auto">
              <br/>
              Convenient&nbsp;to&nbsp;contact&nbsp;you
              <br/>
            </span>
          </div>
        </div>
      </div>
      <div class="box_14 flex-col">
        <div class="text-group_5 flex-col justify-between">
          <span class="text_8">Admissions</span>
          <span class="paragraph_2">
            shanghai.nyu.edu&nbsp;
            <br/>
            shanghai.nyu.edu/undergraduate-admissions
            <br/>
            212-998-4500&nbsp;(New&nbsp;York&nbsp;City)
            <br/>
            +86-21-2059-5599&nbsp;(Shanghai)
          </span>
        </div>
        <span class="text_9">Financial&nbsp;Aid</span>
        <span class="paragraph_3">
          shanghai.nyu.edu/financial.aid
          <br/>
          shanghai.financial.support&#64;nyu.edu
          <br/>
          212-998-2838
        </span>
      </div>

        <div class="outerLink">
          <a href="https://connect.nyu.edu/register/?id=eba99bd7-70d5-4e7d-987d-85971a1c2bec&utm_source=website&utm_medium=button&utm_campaign=mobile-travel-piece">
            <div class="myLink">
              JOIN OUR MAILING LIST!
            </div>
          </a>
      </div>

<!--      <div class="box_21 flex-col">-->
<!--        <span class="text_10" style="height: auto ;    line-height: 8.6vw;">Join&nbsp;our&nbsp;mailing&nbsp;list</span>-->
<!--        <div class="list_3 flex-col">-->
<!--          <div class="input_1 flex-row" v-for="(item, index) in loopData0" :key="index">-->
<!--            <div class="icon_2 flex-col" :style="{ background: item.lanhuBg1 }"></div>-->
<!--            <span class="text_11" v-html="item.lanhutext0"></span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="button_1 flex-col">-->
<!--          <div class="text-wrapper_3">-->
<!--            <span class="text_12">Confirm</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <span class="text_14">-->
<!--          *NYU is committed to protecting personal privacy and is determined to prevent data abuse and excessive intrusion.-->
<!--        </span>-->
<!--      </div>-->
      <myfooter style="margin-top: 5vw" :eng="true">

      </myfooter>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: '',
      phone: '',
      email: '',
      loopData1: [
        {
          lanhutext0: '资讯现场',
          lanhufontColor0: 'rgba(87,6,140,1)',
          lanhutext1: '官方微博',
          lanhufontColor1: 'rgba(87,6,140,1)'
        },
        {
          lanhutext0: '上海市世纪大道1555号上海纽约大学',
          lanhufontColor0: 'rgba(0,0,0,1)',
          lanhutext1: '上海纽约大学本科招生办（新浪微博）',
          lanhufontColor1: 'rgba(0,0,0,1)'
        }
      ],
      constants: {}
    };
  },
  mounted() {
    this.$emit('changeView')
  },
  methods: {}
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss"/>
