<template>
  <div v-if=" $i18n.locale === 'ZH'" class="page6 flex-col">
    <div class="box_1 flex-row" style="height: 13.1vw">
      <div class="block_1 flex-col"></div>
      <span class="text_1">导航</span>
      <div class="block_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="box_2 flex-col">
      <div class="box_3 flex-col"  style="margin-top: 11vw;">
        <div class="box_4 flex-col">
          <div class="group_1 flex-col"></div>
          <div class="group_2 flex-col">
            <div class="block_3 flex-col">
              <div class="box_5 flex-col"></div>
              <span class="paragraph_1" style="width: 50vw;">
                专业细致的
                <br />
                学业支持体系
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-wrapper_2 flex-col">
        <span class="text_5">
          从一入学，上海纽约大学的学生就将获得全面、细致、专业、多样的学术支持资源与服务，帮助他们提高学术水平、学业规划能力以及自主学习能力。
        </span>
      </div>
    </div>
    <div class="box_6 flex-row">
      <div class="text-group_1 flex-col">
        <span class="text_6">一对一学业辅导</span>
        <span class="text_7">
          学校设有学术资源中心，并从哈佛大学、芝加哥大学、宾夕法尼亚大学等世界一流大学选拔优秀毕业生，组成一支实力雄厚的学业辅导老师队伍，针对学生学业的难点和薄弱环节，为学生提供一对一的学业辅导，答疑解惑。内容涉及学术英语写作、英语口语训练、数理化以及其他理工科课程等。
        </span>
      </div>
    </div>
    <div class="box_7 flex-col">
      <div class="middleTitle">
        <span class="text_6">学生感言</span>
        <span class="text_7 eng">/STUDENT&nbsp;JOURNEY</span>
      </div>
      <div class="group_4 flex-row">
        <div class="text-group_2 flex-col">
          <span class="text_11">攻克英语难关</span>
          <span class="text_12">
            学校面向中国新生，特别开设两个学期的学术英语强化课程，帮助学生快速提高英语听说能力，以适应全英语讨论式的学习环境。学校还专为所有大一新生开设英语写作课，并结合“全球视野下的社会”和“人文视角”课程，进行深度阅读和写作训练。
          </span>
        </div>
      </div>
      <div class="group_5 flex-row">
        <div class="text-group_3 flex-col">
          <span class="text_13">学业规划咨询</span>
          <span class="text_14">
            学校为每位学生分配一位专业的学业咨询顾问，帮助学生解读学校各项政策，并结合学生个人目标，协助梳理学术兴趣与选择，制定上海与海外学习期间的学业计划。
          </span>
        </div>
      </div>
      <div class="group_6 flex-row">
        <div class="text-group_4 flex-col">
          <span class="text_15">紧密的师生交流</span>
          <span class="text_16">
            无论是学业问题、研究选题或毕业去向，学生可随时寻求教授的指导帮助。具有丰富学术经验或资深行业背景的教授，是学生在课堂内外学习发展的强大后盾，更是日后求学深造时引荐、推介的重要人脉渠道。
          </span>
        </div>
      </div>
    </div>
<div>
  <nut-collapse style="width: 95vw" v-model="activeNames">
    <nut-collapse-item title="赵英凯 &nbsp;2023届" :name="1">
      <div class="cardA">
        我修读了一门计算机科学课程，但我的计算机是零基础，上课有点吃力。教授每周还会留很多作业，此外还有固定考试。这个时候，学术资源中心就帮上了大忙，我每周都会预约这里计算机领域的学业辅导老师给我补习，指导我攻克作业上的难关。
      </div>

    </nut-collapse-item>
    <nut-collapse-item title="黄心韬&nbsp;2022届" :name="2">
      <div class="cardA">
        学生入校时不用选定专业，所以你有充分探索的自由。不过，因为学校提供的机会实在是太多了，你也许会迷失在各种选择里，而你的每一次“试水”也可能会产生一定成本。这个时候，学业咨询顾问就发挥了重要作用，你可以找他们聊一下如何选课、排课，共同做合理的规划安排，从而在最大程度探索学业兴趣的同时，不浪费时间和学分，降低“试水成本”。

      </div>
    </nut-collapse-item>    <nut-collapse-item title="应雨润&nbsp;2021届" :name="3">
      <div class="cardA">
        因为我是社会科学专业的，平时有大量的英文写作任务，听、说的量也很大，所以我会联系学术资源中心来帮助我。我可以在网站上预约学业辅导老师的时间，一对一地讨论演讲、展示、写作、口语等各种问题，并得到耐心细致和有针对性的指导。当然，学校的全英文教学以及国际化学生群体本身，都让我们一直沉浸在英文环境里，快速、全方位地提升英文水平。
      </div>

    </nut-collapse-item>

  </nut-collapse>
</div>

<myfooter></myfooter>
  </div>
  <div v-else class="page6En eng">
    <div class="block_3 flex-col"><div class="section_1 flex-col"></div></div>
    <div class="group_11 flex-col">
      <div class="box_4 flex-col">
        <div class="text-wrapper_2" style="width: 43.2vw">
          <span class="text_6">LEARN&nbsp;CHINESE…</span>
          <span class="paragraph_1">
            IN CHINA
          </span>
        </div>
      </div>
      <div class="text-wrapper_3 flex-col">
        <span class="text_7">
   Language learning is an integral part of the NYU Shanghai experience. While English is the primary language of instruction, all students graduate proficient in Chinese language, and no previous knowledge of Chinese is required. Outside of the classroom, you’ll be immersed in China’s culture, offering no better way to learn the language.   </span>
      </div>
      <div class="box_5 flex-col">
        <div class="text-wrapper_4">
          <span class="text_8">IMMERSIVE&nbsp;LANGUAGE&nbsp;OPPORTUNITIES</span>
          <span class="text_9">，</span>
          <div class="text_10">
     including field trips to cultural sites, optional summer study, and Chinese language clinics, present valuable ways for you to master the language in addition to your required coursework.    </div>
        </div>
      </div>
    </div>
    <div class="block_5 flex-col">
      <div class="text-wrapper_5" style="margin: 5.46vw 0 0 10.13vw">
        <span class="text_11">ADVANCED&nbsp;LANGUAGE&nbsp;OPTIONS</span>
        <div class="text_12">
  let  you go beyond the basics. You can take courses such as Chinese Literature, Introduction to Business Chinese and Culture, and Contemporary History of China Through Documentaries—or earn credits through accelerated summer immersion programs, which cover two semesters of material in just nine weeks.    </div>
      </div>
    </div>
    <div class="block_6 flex-col">
      <div class="text-wrapper_6">
        <div class="paragraph_2" style="height: auto">
          INTERNSHIPS AND PROFESSIONAL&nbsp;DEVELOPMENT
        </div>
        <div class="text_14">
          give students the chance to practice their language skills in a professional environment and open up job prospects for after graduation. NYU Shanghai offers test prep for HSK mastery, used by international  students to prove proficiency in Mandarin when applying for jobs.  </div>
      </div>
    </div>
    <myfooter :eng="true"></myfooter>
  </div>

</template>
<script>
export default {
  data() {
    return {
      constants: {},
      activeNames:[1]
    };
  },
  methods: {},
  mounted() {
    this.$emit('changeView')
  },
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss" />
