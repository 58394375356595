<template>
  <div id="p7" v-if=" $i18n.locale === 'ZH'" class="page7 flex-col">
    <div class="nav-bar_1 flex-row">
      <div class="block_4 flex-col">
        <div class="section_5 flex-row justify-between">
          <div class="icon_1 flex-col"></div>
          <span class="text_1">导航</span>
        </div>
      </div>
      <div class="block_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="group_1 flex-col">
      <div class="section_2 flex-col">
        <div class="box_1 flex-col"></div>
        <div class="box_2 flex-col">
          <div class="box_3 flex-col">
            <div class="box_4 flex-col"></div>
            <span class="paragraph_1" style="width: 50vw">
              毕业生升学就业
              <br/>
              屡创佳绩
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="group_2 flex-row">
      <span class="paragraph_2">
        上海纽约大学的特色本科
        <br/>
        教育，不仅让学生在升学
        <br/>
        或就业上深受肯定，更将
        <br/>
        持续助推他们的长期发
        <br/>
        展。截止目前，已公布五
        <br/>
        届本科毕业生就业质量报
        <br/>
        告。每届本科毕业生的成
        <br/>
        绩斐然，他们出色的学术
        <br/>
        能力与职业素养，获得社会各界广泛认可。
      </span>
      <span class="text_5">五届毕业生完整就业质量报告</span>
      <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng68561f5cd56b89e49adaa1b337d03945779d59129f5716b54249402f21a7fa67.png"
      />
    </div>
    <div class="group_10 flex-col">
      <span class="text_6">2017-2021届毕业生主要就业单位</span>
      <div class="list_3 flex-col">
        <div
            class="text-wrapper_2 flex-row"
            v-for="(item, index) in loopData0"
            :key="index"
        >
          <span class="text_7" v-html="item.lanhutext0"></span>
          <span class="text_8" v-html="item.lanhutext1"></span>
          <span v-if="item.slot1 === 1" class="text_9">·&nbsp;怡和集团</span>
        </div>
      </div>
      <span class="text_10">2017-2021届毕业生深造情况</span>
      <div class="block_3 flex-col">

        <div class="list_4 flex-row myTable"
             style="display: flex;justify-content: space-between; flex-direction: column">
          <div class="myRow">
            <div class="myCell">

            </div>
            <div class="myCell" style=" color: white;    line-height: 8vw;">
              升学率
            </div>
            <div class="myCell" style="  color: white;     line-height: 8vw;">
              直博率
            </div>
            <div class="myCell" style="  color: white;  width: 25vw;     line-height: 4vw;  margin-left: -5vw;">
              升入USNEWS 全球前50大学比例
            </div>
          </div>
          <div class="myRow" style="padding-top: 1vw">
            <div class="myCell" style="padding-left: 3vw">
              2017届
            </div>
            <div class="myCell">
              51%
            </div>
            <div class="myCell">
              12.5%
            </div>
            <div class="myCell">
              68%
            </div>
          </div>
          <div class="myRow">
            <div class="myCell" style="padding-left: 3vw">
              2018届
            </div>
            <div class="myCell">
              57%
            </div>
            <div class="myCell">
              7.4%
            </div>
            <div class="myCell">
              68%
            </div>
          </div>
          <div class="myRow">
            <div class="myCell" style="padding-left: 3vw">
              2019届
            </div>
            <div class="myCell">
              67%
            </div>
            <div class="myCell">
              8.2%
            </div>
            <div class="myCell">
              65%
            </div>
          </div>
          <div class="myRow">
            <div class="myCell" style="padding-left: 3vw">
              2020届
            </div>
            <div class="myCell">
              68%
            </div>
            <div class="myCell">
              10.2%
            </div>
            <div class="myCell">
              64%
            </div>
          </div>
          <div class="myRow">
            <div class="myCell" style="padding-left: 3vw">
              2021届
            </div>
            <div class="myCell">
              67%
            </div>
            <div class="myCell">
              9%
            </div>
            <div class="myCell">
              72%
            </div>
          </div>
        </div>
        <div class="text-wrapper_27 flex-row">


        </div>
      </div>
      <span class="text_27">2022届毕业生深造去向</span>
      <!--      <div class="tabs_1 flex-row justify-between">-->
      <!--        <div class="section_3 flex-col">-->
      <!--          <div class="group_4 flex-col">-->
      <!--            <div class="box_5 flex-col">-->
      <!--              <div class="text-wrapper_10 flex-col"><span class="text_28">博士项目</span></div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <span class="text_29">硕士项目</span>-->
      <!--      </div>-->
    </div>
    <div class="cc">
      <nut-tab>
        <nut-tab-panel tab-title="博士项目">
          <div class="myRow">
            <div class="myCell">
              <div class="text_30">加州大学伯克利分校</div>
              <div class="text_31">计算机科学</div>
            </div>
            <div class="myCell">
              <div class="text_32">康奈尔大学</div>
              <div class="text_33">法学</div>
            </div>
            <div class="myCell">
              <div class="text_34">伦斯勒理工学院</div>
              <div class="text_35">游戏设计</div>
            </div>
            <div class="myCell">
              <div class="text_36">罗彻斯特大学</div>
              <div class="text_37">光学</div>
            </div>
            <div class="myCell" >
              <div class="paragraph_4">
                马克斯·普朗克—多伦多大学
                <br/>
                神经科学与技术联合研究中心
              </div>
              <div class="text_38">生物医学工程</div>
            </div>
            <div class="myCell">
              <div class="text_39">牛津大学</div>
              <div class="text_40">实验心理学</div>
            </div>
            <div class="myCell">
              <div class="text_41">纽约大学</div>
              <div class="paragraph_5" >
                &nbsp;·计算机科学
                <br/>
                &nbsp;·生物学
              </div>
            </div>
            <div class="myCell">
              <div class="text_42">香港科技大学（广州）</div>
              <div class="text_43">城市治理与设计</div>
            </div>
            <div class="myCell">
              <div class="text_44">新加坡国立大学</div>
              <div class="text_45">法律</div>
            </div>
            <div class="myCell">
              <div class="text_46">佐治亚理工学院</div>
              <div class="text_47">机器学习（生物医疗工程）</div>
            </div>
          </div>
        </nut-tab-panel>
        <nut-tab-panel tab-title="硕士项目">
          <div class="myRow">
            <div class="myCell">
              <div class="text_30">哈佛大学</div>
              <div class="text_31">东亚研究
                <br/>
                数据科学 </div>
            </div>
            <div class="myCell">
              <div class="text_32">麻省理工学院 </div>
              <div class="text_33">金融学
                <br/>
                商业分析</div>
            </div>
            <div class="myCell">
              <div class="text_34">加州大学伯克利分校  </div>
              <div class="text_35">工业工程与运筹学
                <br/>

                设计学
                <br/>

                信息管理与信息系统</div>
            </div>
            <div class="myCell">
              <div class="text_36">哥伦比亚大学  </div>
              <div class="text_37">国际金融和经济政策
                <br/>
                应用分析学
                <br/>
                战略传播</div>
            </div>
            <div class="myCell" >
              <div class="paragraph_4">
                剑桥大学
              </div>
              <div class="text_38">媒介与文化社会学
                <br/>

                音乐认知</div>
            </div>
            <div class="myCell">
              <div class="text_39">清华大学</div>
              <div class="text_40">国际关系（约翰·霍普金斯大学联合培养项目）
                <br/>

                全球事务 （苏世民学者项目）</div>
            </div>
            <div class="myCell">
              <div class="text_42">约翰·霍普金斯大学 </div>
              <div class="text_43">国际教学与全球领导力教育
                <br/>

                社会因素与健康</div>
            </div>
            <div class="myCell">
              <div class="text_44">耶鲁大学</div>
              <div class="text_45">国际与发展经济学
                <br/>

                统计学
                <br/>

                生物统计学</div>
            </div>
          </div>
        </nut-tab-panel>

      </nut-tab>
    </div>


    <div class="group_11 flex-col">
      <span class="text_48">2022届毕业生就业去向</span>
      <div class="section_4 flex-row" style="  ">
        <div class="text-group_2 flex-col">
          <span class="paragraph_6">
            ·&nbsp;安永
            <br/>
            ·&nbsp;毕马威
            <br/>
            ·&nbsp;贝恩
          </span>
          <span class="text_49">·&nbsp;中国石油</span>
        </div>
        <span class="paragraph_7">
          ·&nbsp;&nbsp;德勤
          <br/>
          ·&nbsp;花旗银行
          <br/>
          ·&nbsp;华为
        </span>
        <span class="paragraph_8">
          ·&nbsp;摩根大通
          <br/>
          ·&nbsp;摩根士丹利
          <br/>
          ·&nbsp;耐克体育
        </span>
      </div>
      <span class="paragraph_9">
        注：以上信息为不完全统计，更多数据
        <br/>
        还请关注2023年初发布的2022届本科毕业生就业质量报告。
      </span>
    </div>
    <myfooter >

    </myfooter>
  </div>
  <div v-else class="page7En eng flex-col">
      <div class="box_10 flex-col"><div class="group_7 flex-col"></div></div>
      <div class="box_11 flex-col">
        <div class="text-wrapper_2">
        <span class="paragraph_1">
          A&nbsp;NETWORK&nbsp;OF&nbsp;
          <br />
        </span>
          <span class="text_6">OPPORTUNITY</span>
        </div>
      </div>
      <div class="box_4 flex-col">
        <div class="text-wrapper_3">
        <span class="text_7">
     NYU SHANGHAI STUDENTS ARE A STEP AHEAD WHEN THEY ENTER THE JOB MARKET,
        </span>
          <span class="text_9" style="line-height:  1.54vw">
  thanks to NYU Shanghai’s focus on graduating students who are experts on China as well as in their field of study. While they are in school, the University’s Career Development Center (CDC) helps them find internships, develop professional-level skills, and connect with alumni in their field through the Alumni Executive Mentor Program. When they graduate, they will have access to both NYU and NYU Shanghai alumni around the world.   </span>
        </div>
      </div>
      <div class="group_11 flex-col">
        <span class="text_10">CLASS&nbsp;OF&nbsp;2021</span>
        <div class="block_11 flex-row">
          <div class="text-wrapper_4">
            <span class="text_11">93</span>
            <span class="text_12">%</span>
          </div>
          <span class="text_13">
          of&nbsp;graduates&nbsp;are&nbsp;either&nbsp;employed&nbsp;or&nbsp;enrolled&nbsp;in&nbsp;graduate&nbsp;school&nbsp;within&nbsp;one&nbsp;year&nbsp;after&nbsp;graduation
        </span>
        </div>
        <div class="block_12 flex-row">
          <div class="block_21 flex-col justify-between">
            <div class="text-wrapper_5">
              <span class="text_14">43</span>
              <span class="text_15">%</span>
            </div>
            <span class="paragraph_2">
              <div>
                            are working at

              </div>
              <div>
              companies like

              </div>

          </span>
          </div>
          <span class="paragraph_3">
          ·&nbsp;Amazon
          <br />
          ·&nbsp;Unilever
          <br />
          ·&nbsp;Deloitte
        </span>
          <span class="paragraph_4">
          ·&nbsp;Microsoft
          <br />
          ·&nbsp;JPMorgan&nbsp;Chase
          <br />
          ·&nbsp;UBS
          <br />
          ·&nbsp;McKinsey&nbsp;&amp;&nbsp;Company
          <br />
          ·&nbsp;180&nbsp;degrees&nbsp;consulting
          <br />
          ·&nbsp;The&nbsp;Walt&nbsp;Disney&nbsp;Company
        </span>
        </div>
        <div class="block_13 flex-row">
          <div class="block_22 flex-col justify-between">
            <div class="text-wrapper_6">
              <span class="text_16">50</span>
              <span class="text_17">%</span>
            </div>
            <span class="text_18">are studying at top global universities like</span>
          </div>
          <span class="paragraph_5" style="width: 56.4vw">
            <div>
                        · National University of Singapore&nbsp;

            </div>
          ·&nbsp;Princeton&nbsp;University
          <br />
          ·&nbsp;Stanford&nbsp;University&nbsp;
          <br />
          ·&nbsp;Tsinghua&nbsp;University&nbsp;
          <br />
          ·&nbsp;University&nbsp;of&nbsp;Oxford
          <br />
          ·&nbsp;University&nbsp;of&nbsp;Sydney
        </span>
        </div>
      </div>
      <div class="box_13 "  style="padding: 11vw 2vw" >
        <div style="overflow-x: scroll ; display: flex ;">
          <div class="box_7 flex-col">
            <div class="image-text_9 flex-col" style="">
              <svg-icon icon-class="page7-logo (2)"></svg-icon>
              <span class="text-group_1" style="">62%</span>
            </div>
            <span class="text_21 eng">are living outside their home country</span>
          </div>
          <div class="box_7 flex-col">
            <div class="image-text_9 flex-col" style="">
              <svg-icon icon-class="page7-logo (3)"></svg-icon>
              <span class="text-group_1" style="">4</span>
            </div>
            <span class="text_21 eng">graduates awarded  prestigious global scholarships and
fellowships</span>
          </div>
          <div class="box_7 flex-col">
            <div class="image-text_9 flex-col" style="">
              <svg-icon icon-class="page7-logo (4)"></svg-icon>
              <span class="text-group_1" style="">35%</span>
            </div>
            <span class="text_21 eng">of graduates
remained in Asia</span>
          </div>
          <div class="box_7 flex-col">
            <div class="image-text_9 flex-col" style="">
              <svg-icon icon-class="page7-logo (1)"></svg-icon>
              <span class="text-group_1" style="">18</span>
            </div>
            <span class="text_21 eng">The number of
countries and regions where graduates hold jobs</span>
          </div>
        </div>

      </div>
      <div class="text-wrapper_11 flex-col"><span class="text_25">STUDENT&nbsp;STORIES</span></div>
      <div class="box_16 flex-col" style="padding-top: 15vw">
        <div class=" list_2 flex-row" style="justify-content: space-around;">
          <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===1}  " @click="handleShow(1)"
                    icon-class="page3stu (1)"></svg-icon>
          <svg-icon class="list-items_1 flex-col" :class=" { active: currnetStu===2}  " @click="handleShow(2)"
                    icon-class="page3stu (2)"></svg-icon>
        </div>
        <div  style="font-size: 3vw" v-show="currnetStu  === 1">
          <!--        <p class="text_58">张珺安</p>-->
          <div class="text_59" style=" padding: 5vw  5vw; font-size: 3.5vw;  line-height: 4.5vw">
            &nbsp;&nbsp;Benefited from locating in the Lujiazui Financial and Trade Zone in the core area of Pudong, Yizhen Luo, who is from the Class of 2019, had obtained career information and resources both on and off campus. Through the various workshops and social activities held by the Career Development Center, he have been able to understand the career development prospects of different industries, and to finally find my true interest. </div>
        </div>
        <div  style="font-size: 3vw" v-show="currnetStu  === 2">
          <!--        <p class="text_58">李慧琳</p>-->
          <div class="text_59" style="padding: 5vw; font-size: 3.5vw; line-height: 4.5vw ">

            He Ziqing, who’s from the Class of 2020, had been studied away at NYU’s Stern School of Business, and had the chance to design a real marketing plan for an education nonprofit organization. The exposure to the highly variable, in-depth work of consulting led He to an internship at Shanghai-based strategic sustainability advisory firm Collective Responsibility in summer and fall 2019, and ultimately to a full-time position at PwC’s new Integrated Urban Services division in Shanghai after graduation.   </div>
        </div>

      </div>

      <myfooter :eng="true">

      </myfooter>
    </div>
</template>
<script>
export default {
  data() {
    return {
      currnetStu: 1,
      loopData0: [
        {
          lanhuBg0:
              'url(/img/af367032f24240f199f8c23169ff8e00_mergeImage.279a5553.png) 100% no-repeat',
          lanhutext0: '·&nbsp;阿里巴巴<br/>·&nbsp;华为<br/>·&nbsp;腾讯',
          lanhutext1: '·&nbsp;微软<br/>·&nbsp;亚马逊<br/>·&nbsp;字节跳动'
        },
        {
          lanhuBg0:
              'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng137402ad7c9d5612fae2a78753aa34f3ab8814938c97398c0bd11c912ac72d6b) 100% no-repeat',
          lanhutext0: '·&nbsp;安永毕马威<br/>·&nbsp;德勤<br/>·&nbsp;罗兰贝格',
          lanhutext1: '·&nbsp;麦肯锡蓝跃<br/>·&nbsp;普华永道'
        },
        {
          lanhuBg0:
              'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng10a2fadbabaa4783e286127e7122fc3875da3df357a80551b2a04b5424ef9e0b) 100% no-repeat',
          lanhutext0: '·&nbsp;花旗银行<br/>·&nbsp;摩根大通<br/>·&nbsp;瑞银银行',
          lanhutext1: '·&nbsp;中国工商银行<br/>·&nbsp;中金财富<br/>·&nbsp;招商银行'
        },
        {
          lanhuBg0:
              'url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng137402ad7c9d5612fae2a78753aa34f3ab8814938c97398c0bd11c912ac72d6b) 100% no-repeat',
          lanhutext0: '·&nbsp;联合利华<br/>·&nbsp;美丽中国<br/>·&nbsp;欧莱雅',
          lanhutext1: '·&nbsp;群邑<br/>·&nbsp;WeWork<br/>·&nbsp;西门子医疗',
          slot1: 1
        }
      ],
      constants: {}
    };
  },
  mounted() {
    this.$emit('changeView')
  },
  methods: {
    handleShow(e) {
      this.currnetStu = e
    },
  }
};
</script>
<style lang="scss" src="./assets/index.response.scss"/>
