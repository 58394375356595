<template>
  <div v-if=" $i18n.locale === 'ZH'" class="page8 flex-col">
    <div class="nav-bar_1 flex-row">
      <div class="icon_1 flex-col"></div>
      <span class="text_1">导航</span>
      <div class="box_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="group_3 flex-col">
      <div class="block_3 flex-col"></div>
      <div class="block_4 flex-col">
        <div class="box_3 flex-col">
          <div class="box_4 flex-col"></div>
          <span class="paragraph_1" style="width: 60vw">
            无论在任何地方
            <br/>
            你始终是纽约大学一员
          </span>
        </div>
      </div>
    </div>
    <div class="text-wrapper_2 flex-col">
      <span class="paragraph_2">
        海外学习是上海纽约大学四年本科培养和学习体验中不可或缺的重要环节。每一位学生均可根据个人专业兴趣与志向选择海外学习目的地，有保障地进行一到两个学期的海外学习。有别于常见的校际交换项目，学生在海外学习期间，课程体系无缝衔接，师资水平、科研资源与学生服务系统全球统一，并可修读学科领域覆盖广泛的海量课程。海外学习期间学费标准不变。
        <br/>
        <br/>
        与本科直接出国留学相比，在上海纽约大学就读，学生既能享有独具特色的博雅教育，又能紧密把握中国根基与人脉网络，为毕业后的人生拓宽选择面。
      </span>
    </div>
    <div class="middleTitle" style="margin-top: 3vw ;margin-bottom: 5vw">
      <span class="text_6">全球教学点</span>
      <span class="text_7 eng">/GLOBAL&nbsp;NETWORK</span>
    </div>
    <div class="block_6 flex-col" @click="handleJumpToMap()">
      <div class="box_7 flex-row">
        <div class="icon_2 flex-col"></div>
        <div class="text-group_3 flex-col">
          <span class="text_7">3</span>
          <span class="text_8">门户校园</span>
        </div>
        <div class="icon_3 flex-col"></div>
        <div class="text-group_4 flex-col">
          <span class="text_9">12</span>
          <span class="text_10">海外学习中心</span>
        </div>
      </div>
      <div class="box_8 flex-row">
        <div class="icon_4 flex-col"></div>
      </div>
    </div>
    <div @click="handleJumpToMap()" class="text-wrapper_14 flex-col"
         style="display: flex ;    margin: 6vw auto 1px auto;">
      <span class="text_56">点击查看更多</span>
    </div>
    <div class="group_5 flex-col">
      <div class="middleTitle">
        <span class="text_6">学生感言</span>
        <span class="text_7 eng">/STUDENT&nbsp;JOURNEY</span>
      </div>
    </div>
    <div>
      <nut-collapse style="width: 95vw" v-model="activeNames">

        <nut-collapse-item title="卢子杰&nbsp;2019届" :name="2">
          <div class="cardA">
            因为数据科学和计算机科学这两个方向在纽约大学开设的课程都很丰富，我两个学期都在纽约进行海外学习。值得一提的是，纽约大学于2013年创办了数据科学中心，并开设了数据科学硕士项目。这一点的好处在于，我们本科生在大三、大四时可以申请在数据科学中心修读研究生、博士生级别的高阶课程。当时我们抓住了这个机会，一边修读研究生级别的课程，一边参与教授的研究项目，这一年下来对我科研能力的培养大有帮助。
          </div>
          <div class="cardB flex-col">
            <div class="cardC">

              <div style="    margin: 2vw;">
                                        <span class="paragraph_2">
                                             <br/>
                                            毕业中学：上海外国语大学附属浦东外国语学校
                                            <br/>
                                            <br/>
                                            本科专业：计算机科学
                                            <br/>
                                            <br/>
                                            辅修：公共政策与管理
                                            <br/>
                                            <br/>
                                            毕业去向：伊利诺伊大学香槟分校 计算机科学硕士研究生  Facebook  软件工程师
                                          </span>
              </div>

            </div>

          </div>
        </nut-collapse-item>
      </nut-collapse>
    </div>
    <myfooter></myfooter>
  </div>
  <div v-else class="page8 flex-col eng">
    <div class="nav-bar_1 flex-row">
      <div class="icon_1 flex-col"></div>
      <span class="text_1">导航</span>
      <div class="box_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="group_3 flex-col">
      <div class="block_3 flex-col"></div>
      <div class="block_4 flex-col">
        <div class="box_3 flex-col" style="">
          <div class="box_4 flex-col"></div>
          <span class="paragraph_1" style="width: 80vw;">
            NO MATTER WHERE YOU GO
            <br/>
          YOU’RE STILL AT NYU
          </span>
        </div>
      </div>
    </div>
    <div class="text-wrapper_2 flex-col">
      <span class="paragraph_2">
   <span style="color: #57068C">
     WHEREVER YOU CHOOSE TO STUDY, YOU GET AN NYU EDUCATION
   </span>
  <br/>
        <br/>
 because the coursework at the global academic centers and programs is developed in consultation with NYU’s three degree-granting campuses. At NYU Shanghai, you have the opportunity to spend as many as two semesters studying abroad in NYU’s global network.  </span>
    </div>
    <div class="middleTitle" style="margin-top: 5vw;margin-bottom: 5vw">
      <span class="text_6" >NYU GLOBAL NETWORK</span>
    </div>
    <div class="block_6 flex-col" @click="handleJumpToMap()">
      <div class="box_7 flex-row">
        <div class="icon_2 flex-col" style="margin-top: -3vw"></div>
        <div class="text-group_3 flex-col">
          <span class="text_7" style="margin-top: -3vw">3</span>
          <span class="text_8" style="margin-left: -10vw;">
            <div>
              degree-granting
            </div>
                 <div>
              campuses
            </div>
            </span>
        </div>
        <div class="icon_3 flex-col" style="margin-top: -3vw"></div>
        <div class="text-group_4 flex-col">
          <span class="text_9" style="margin-top: -3vw">12</span>

          <span class="text_10" style="margin-left: -10vw;">
            <div>
             global academic
            </div>
                 <div>
             centers and programs
            </div></span>
        </div>
      </div>
      <div class="box_8 flex-row">
        <div class="icon_4 flex-col"></div>
      </div>
    </div>
    <myfooter :eng="true" style="margin-top: 15vw"></myfooter>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
      activeNames: [1]
    };
  },
  mounted() {
    this.$emit('changeView')
  },
  methods: {
    handleJumpToMap() {
      this.$router.push('/lanhu_88quanqiujiaoxuetixizhankai')
    }
  }
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss"/>
