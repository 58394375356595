<template>
  <div v-if=" $i18n.locale === 'ZH'" class="page9 flex-col">
    <div class="group_1 flex-row">
      <div class="block_1 flex-col"></div>
      <span class="text_1">导航</span>
      <div class="block_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="section_2 flex-col">
      <div class="block_3 flex-col">
        <div class="group_3 flex-col">
          <div class="group_4 flex-col"></div>
          <div class="group_5 flex-col">
            <div class="section_1 flex-col">
              <div class="block_4 flex-col"></div>
              <span class="paragraph_1" style="width: 60vw;">
                在上海把握区位优势
                <br/>
                沉浸多元化社群
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-wrapper_2 flex-col">
        <span class="text_5">
          坐落于浦东陆家嘴金融贸易区核心地带，上海纽约大学秉承纽约大学“立足城市，融入城市”的理念，充分利用上海这座国际化大都市的经济、智力、文化和科技资源，为学生创造了独一无二的教学体验，接触来自全球的优秀人才，享受大量实习实践及工作机会。高度国际化的学生群体，让学生24小时沉浸在跨文化交流环境中。
        </span>
      </div>
      <div class="text-wrapper_12 flex-col">
        <span class="text_6">
          上海纽约大学现有在校本科生1800余人，来自全球70多个国家和地区。每届学生中，中国学生、国际学生各半，构成一个真正国际化、多元化的学生群体。
        </span>
      </div>
      <div class="text-wrapper_13 flex-col">
        <span class="text_7">
          学校所有课程的教学语言均为英语，从入学第一天起，学生就要迎接语言上的挑战和文化上的碰撞。在这一多元文化环境中沉浸四年后，学生将熟练掌握跨文化沟通技能。
        </span>
      </div>
      <div class="text-wrapper_14 flex-col">
        <span class="text_8">
          上海纽约大学有包括模拟联合国、戏剧、音乐、舞蹈、绿色上海等约30个学生社团。每年，还会举办真人秀音乐剧、Ally&nbsp;Week等校传统活动，促进交流与融合，激发新的视角与思考。学校拥有足球、篮球、排球、网球等多支校队，并在校际间比赛中斩获佳绩，还提供丰富的运动健身活动，帮助学生保持健康活力。
        </span>
      </div>
      <div class="text-wrapper_15 flex-col">
        <span class="text_9">
          &nbsp;与来自世界各地的同学一起住宿并参加宿舍区活动，是上海纽约大学多元文化体验的重要一环。上海纽约大学要求所有大一、大二学生住在宿舍。每位新生均有至少一名来自不同国家和地区的室友，实现课堂内外不间断的交流。
        </span>
      </div>
      <div class="text-wrapper_16 flex-col">
        <span class="text_10">
          &nbsp;得天独厚的地理位置与深植本地的校企关系，便于学生兼顾学习、实习与课外实践。上海纽约大学被列入非上海生源第一类高校落户名单，是国内唯一一家被上海市政府纳入一类落户名单的中外合办高校。
        </span>
      </div>
    </div>
    <div class="text-wrapper_17 flex-col">
      <span class="text_11">
        上海纽约大学前滩校区的建设已进入最终装饰阶段，将于2022年年底正式入驻。新校区所在的前滩，已被上海规划打造为新的世界级中央商务区，将成为第二个“陆家嘴”。前滩新校区由国际著名建筑事务所Kohn&nbsp;Pedersen&nbsp;Fox（KPF）设计。和目前的世纪大道校区相比，新校区面积扩大近一倍，将拥有面积约5000平方米的图书馆、7000平方米的实验室与研究空间，以及运动场地等。地面以上层高共10层。设计方案设置了各类户外空间，其中20多块小绿地加之宽敞的中央庭院，覆盖了约86%的户外面积。上海纽约大学前滩新校区项目以其卓越的校区规划设计赢得了学院及大学规划学会（SCUP）2019年度规划、建筑设计和景观建筑类大奖，成为第一个获此殊荣的中国项目。
      </span>
    </div>
    <div class="section_3 flex-col">
      <div class="section_4 flex-row justify-between">
        <span class="text_12">学生感言</span>
        <div class="text-wrapper_9">
          <span class="text_13">/</span>
          <span class="text_14 eng">STUDENT&nbsp;JOURNEY</span>
        </div>
      </div>
    </div>
    <div>

      <nut-collapse style="width: 95vw" v-model="activeNames">
        <nut-collapse-item title="才静远&nbsp;2022届" :name="2">
          <div class="cardA">
            “我会想念一直鼓励我大胆尝试、勇于发声的教授们，想念与我谈天说地甚至争论不休的同学们。不同文化背景的人在这里相遇，我们在包容的氛围里见证了多元文化间的交织碰撞，在有限的空间里，把世界当作学习的课堂。”
          </div>
          <div class="cardB flex-col">
            <div class="cardC">
              <div style="    margin: 2vw;">
                                        <span class="paragraph_2">
                                            <br/>
                                            本科专业：金融学专业、世界史（社会科学）专业（国际关系方向）
                                            <br/>
                                            <br/>
                                            毕业去向：
                                       美国哥伦比亚大学国际公共事务学院（SIPA）研究生  </span>
              </div>
            </div>
          </div>
        </nut-collapse-item>
      </nut-collapse>
    </div>
    <myfooter></myfooter>
  </div>
  <div v-else class="page9 flex-col eng">
    <div class="group_1 flex-row">
      <div class="block_1 flex-col"></div>
      <span class="text_1">导航</span>
      <div class="block_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="section_2 flex-col">
      <div class="block_3 flex-col">
        <div class="group_3 flex-col">
          <div class="group_4 flex-col"></div>
          <div class="group_5 flex-col">
            <div class="section_1 flex-col">
              <div class="block_4 flex-col"></div>
              <span class="paragraph_1" style="width: 60vw;">
                LIFE AT
                <br/>
                NYU SHANGHAI
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-wrapper_2 flex-col" style="padding: 5.6vw 8.06vw 8.26vw 6.4vw; height: 62vw;    background-size: 100% 100%;">
        <span class="text_5" style="width: auto">
    “We will be so proud to welcome students, faculty, and scholars from all over the world. The new campus combines features from the East and the West. It connects the present with the future. With such a great campus, I am confident that we will make a lasting and vital contribution to Pudong, to Shanghai, to China and to the world.”
                  <div style="    text-align: right;" >
          —— Chancellor Tong Shijun
        </div>
   </span>


      </div>

    </div>
    <nut-collapse style="width: 95vw ;color:#57068C; "   v-model="activeNames1">


      <nut-collapse-item title="      <div   style='color:#57068C;'  >
        <div>
          SHANGHAI SEAMLESSLY MIXES
        </div>
        <div>
          THE ANCIENT AND THE MODERN
        </div>
      </div>" :name="2">
        <div class="cardA">
          Gardens and temples that have existed for hundreds of years mingle
          with towering glass skyscrapers and neon-lit shopping malls. But
          there’s another Shanghai, one with the charm of small neighborhoods
          and local businesses that thrive amid the hustle and bustle of the big
          city, and you can explore it all with confidence because Shanghai is
          safe and welcoming.
        </div>

      </nut-collapse-item>
      <nut-collapse-item title="      <div   style='color:#57068C;'  >

        <div>
          CAFES LINE THE STREETS
        </div>
      </div>" :name="1">
        <div class="cardA">
          of almost every sector of the city,
          and many are themed. There’s even a Friends–inspired cafe called
          Central Perk.
        </div>

      </nut-collapse-item>
      <nut-collapse-item title="      <div   style='color:#57068C;'  >

        <div>
         RESTAURANTS OF EVERY KIND
        </div>
      </div>" :name="1">
        <div class="cardA">
          like the classroom-themed 1990s
          Memory Museum and the quirky Hello Kitty Hotpot offer dining
          experiences unique to Shanghai, while brunch spots like Al’s Diner
          and Liquid Laundry Kitchen provide the familiar flavors of home.
        </div>
      </nut-collapse-item>

      <nut-collapse-item title="      <div   style='color:#57068C;'  >

        <div>
         SHOPS SELLING ONE-OF-A-KIND
        </div>      <div>
       HANDICRAFTS
        </div>
      </div>" :name="1">
        <div class="cardA">
          are mixed in with
          stores selling international brands and are a great place to snag
          unique dorm room decor or gifts to send home to the family.
        </div>

      </nut-collapse-item>

      <nut-collapse-item title="      <div   style='color:#57068C;'  >

        <div>
        EXPLORE ONE OF THE WORLD’S
        </div>      <div>
       RICHEST CULINARY TRADITIONS
        </div>
      </div>" :name="1">
        <div class="cardA">
          with favorites from every corner of China available, from Shanghai
          staples, such as fried pork buns and spring rolls, to Peking duck, halal
          Lanzhou beef noodles, and eye-wateringly spicy Sichuan tofu.
        </div>

      </nut-collapse-item>
    </nut-collapse>

    <div class="section_3 flex-col">
      <div class="section_4 flex-row justify-between">
        <span class="text_12 eng">STUDENT&nbsp;JOURNEY</span>

      </div>
    </div>
    <div>

      <nut-collapse style="width: 95vw" v-model="activeNames">
        <nut-collapse-item title="Shy Mitchell, Class of 2020" :name="2">
          <div class="cardA">
            “Cafes are located in almost every district with most
            being in Xuhui, Jing’an, and Pudong. The styles of the
            cafes all vary with different themes and types. There are
            book cafes, concept cafes, and exotic ones like cat cafes.
            All of them are worth the trip, and one of them may
            remind you of your favorite cafe back home.”
          </div>

        </nut-collapse-item>
      </nut-collapse>
    </div>
    <myfooter :eng="true"></myfooter>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
      activeNames: [],
      activeNames1: []
    };
  },
  mounted() {
    this.$emit('changeView')
  },
  methods: {}
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss"/>
