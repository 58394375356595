<template>
  <div class="page flex-col">
    <div class="section_1 flex-row">
      <div class="box_1 flex-col"></div>
      <span class="text_1">导航</span>
      <div class="box_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2">EN</span>
        <span class="text_3">|</span>
        <span class="text_4">中文</span>
      </div>
    </div>
    <div class="block_8 flex-col">
      <div class="group_1 flex-col">
        <div class="box_3 flex-col">
          <div class="block_1 flex-col"></div>
          <div class="block_2 flex-col">
            <div class="group_2 flex-col" style="width: 50vw">
              <div class="box_4 flex-col"></div>
              <span class="paragraph_1"  style="width: 60vw">
                精准职业规划&nbsp;优质线上资源
                <br />
                全球校友网络
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-wrapper_2 flex-col">
        <span class="text_5">
          学校为学生提供全方位、精准化的职业发展辅导。职业发展辅导贯穿四年学习，让新生从开学第一天，便开始思考大学毕业乃至更长远的职业与人生规划。
        </span>
      </div>
      <div class="text-wrapper_10 flex-col">
        <span class="text_6">
          学校依托纽约大学全球就业体系，设有职业发展中心为所有在校生进行系统职业规划。职业发展中心遵循学生职业发展规律与特点，推出生涯启航工作坊、行业兴趣小组、纽约大学校友导师项目、林军行业导师项目、“潜能无限”学生职业发展年会、暑期公益服务资金等项目及活动，链接学生本科四年职业发展，从不同层面为学生提供宝贵经验与丰富资源。
        </span>
      </div>
      <div class="text-wrapper_11 flex-col">
        <span class="text_7">
          每学期，职业发展中心都会举办至少一场校园招聘会，邀请超过60家企业进驻校园招聘，为学生提供展示自我，与雇主面对面交流的机会；还会举办覆盖不同行业领域的企业宣讲会，帮助学生快速了解行业发展动向，与雇主建立直接联系。同时，学生可利用Handshake服务平台获取海量实习及工作岗位信息。该平台目前已有全球超过30万雇主进驻，包括全部世界500强企业。
        </span>
      </div>
      <div class="text-wrapper_12 flex-col">
        <span class="text_8">
          职业发展中心根据学生需求，依托本土及纽约大学体系下各类优质线上资源，自主研发并成立了职业生涯线上教育创新中心——生涯实验室。生涯实验室线上资源中心由基础技能课、数字工具箱与视频图书馆三大板块组成，分别覆盖基础求职课程教育、就创业技能练习、职涯视野拓宽等功能，运用科技力量赋能学生职业发展。
        </span>
      </div>
      <div class="text-wrapper_13 flex-col">
        <span class="text_9">
          学生享有的资源不止于毕业，与纽约大学的纽带将维系一生。学生可加入纽约大学遍布全球180多个国家、人数超过60万的全球校友网络。密集的校友线下活动，为信息资源共享创造优渥条件。
        </span>
      </div>
<!--      <div class="group_4 flex-row justify-between">-->
<!--        <span class="text_10">学生感言</span>-->
<!--        <div class="text-wrapper_7">-->
<!--          <span class="text_11">/</span>-->
<!--          <span class="text_12">STUDENT&nbsp;JOURNEY</span>-->
<!--        </div>-->
<!--      </div>-->
      <div class="middleTitle">
        <span class="text_6">学生感言</span>
        <span class="text_7 eng">/STUDENT&nbsp;JOURNEY</span>
      </div>
    </div>
    <div>
      <nut-collapse style="width: 95vw" v-model="activeNames">

        <nut-collapse-item title="罗轶桢&nbsp;2019届" :name="2">
          <div class="cardA">
            上海纽约大学位于浦东核心地区的陆家嘴金融贸易区，这一优越的地理位置便于我获得校内外的职业信息和资源。通过职业发展中心举办的各类工作坊和社交活动，我得以了解不同行业的职业发展前景，逐渐找到真正的兴趣所在。由于学校靠近四条地铁线，几站之内就能轻松抵达浦西和浦东的多个商业区，我甚至可以在下课后前往浦西实习。其中的一次实习机会，让我获得了现在的全职工作岗位。
          </div>
          <div class="cardB flex-col">
            <div class="cardC">
              <div style="    margin: 2vw;">
                                        <span class="paragraph_2">
                                            <br/>
                                            毕业中学：成都石室中学
                                            <br/>
                                                                                      <br/>

                                            本科专业：金融学（商学与市场营销） 数据科学
                                            <br/>
                                            <br/>
                                            毕业去向：罗兰贝格管理咨询有限公司 咨询顾问
                                          </span>
              </div>

            </div>

          </div>
        </nut-collapse-item>
      </nut-collapse>
    </div>
    <myfooter></myfooter>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
      activeNames: [1]
    };
  },
  mounted() {
    this.$emit('changeView')
  },
  methods: {}
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss" />