<template>
  <div :style="outerStyle"  class="svgOuter">
    <svg  :class="svgClass" :style="svgStyle" aria-hidden="true" v-on="$listeners">
      <use :xlink:href="iconName" :fill="iconColor" />
    </svg>
  </div>

</template>

<script>
// doc: https://panjiachen.github.io/vue-element-admin-site/feature/component/svg-icon.html#usage

export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    diff1: {
      type: Boolean,
      required: false
    },
    svgStyle: {
      type: Object,
    },
    outerStyle: {
      type: Object,

    },
    className: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: "white"
    }
  },
  computed: {

    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.iconClass}) no-repeat 50% 50%`
      }
    }
  }
}
</script>

<style scoped>
.svgOuter{
  display: flex;justify-content: center;align-items: center;
}
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover!important;
  display: inline-block;
}
</style>
