<template>

  <div class="hello">
    <div id="main"></div>
  </div>
</template>

<script>

import axios from 'axios'
import * as echarts from 'echarts';

export default {

  name: "EchartPractice",

  data() {
    return {
      myEchart: {},
      takenSeatNames: ['shanghai'],
      setLayoutSzie: '110%',
      option: {
        layoutCenter: ['20%', '80%'],
        layoutSize: '100%',
        tooltip: {},
        geo: {
          // center: [115.97, 29.71],
          // left: 0, top: 0, right: 0, bottom: 0,
          // boundingCoords: [
          //   // 定位左上角经纬度
          //   [-4.94963 , 57.01337 ],
          //   // 定位右下角经纬度
          //   [-4.94963 , 57.01337 ]
          // ],
          map: 'seats',
          roam: true,
          selectedMode: 'single',
          tooltip: {
            show: false
          },
          scaleLimit: {
            min: 2,
            max: 5
          },
          itemStyle: {
            color: 'rgba(61,4,98,0.95)',
            borderWidth: 6,
            borderColor: '#fff ',
            shadowColor: '#BFBEBB',
            shadowBlur: 8,
            shadowOffsetY: 2
          },
          emphasis: {
            focus: 'none',
            label: {
              show: false
            }
          },
          select: {
            itemStyle: {
              color: 'rgba(61,4,98,0.95)',
              borderWidth: 5,
              borderColor: 'rgba(242,234,246,0.64)'
            },
            label: {
              show: false,
            }
          },
        },
      }
    };
  },
  computed: {
    myvw() {
      return document.documentElement.clientWidth
    },
    myvh() {
      return document.documentElement.clientHeight
    }
  },
  methods: {
    makeTakenRegions(takenSeatNames) {
      let regions = [];
      for (let i = 0; i < takenSeatNames.length; i++) {
        regions.push({
          name: takenSeatNames[i],
          select: true
        });
      }
      return regions;
    },
    showCity(city) {
      console.log(city)
      switch (city) {
        case 'shanghai' :
          this.option.layoutCenter = ['0%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',

            name: 'shanghai',

          })
          break
        case 'newyork' :
          this.option.layoutCenter = ['120%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',

            name: 'NEWYORK',

          })
          break
        case 'abzb' :
          this.option.layoutCenter = ['40%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'abzb',
          })
          break
        case 'LA' :
          this.option.layoutCenter = ['110%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,

              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',

            name: 'LA',

          })
          break
        case 'DC' :
          this.option.layoutCenter = ['80%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',

            name: 'DC',

          })
          break
        case 'LD' :
          this.option.layoutCenter = ['50%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'LD',
          })
          break
        case 'FLLS' :
          this.option.layoutCenter = ['30%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'FLLS',
          })
          break
        case 'MDL' :
          this.option.layoutCenter = ['50%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'MDL',
          })
          break
        case 'BL' :
          this.option.layoutCenter = ['40%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'BL',
          })
          break
        case 'BYNSALS' :
          this.option.layoutCenter = ['90%', '30%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'BYNSALS',
          })
          break
        case 'paris' :
          this.option.layoutCenter = ['40%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'paris',
          })
          break
        case 'TLWF' :
          this.option.layoutCenter = ['40%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'TLWF',
          })
          break


        case 'XN' :
          this.option.layoutCenter = ['-10%', '20%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'XN',
          })
          break
        case 'BLG' :
          this.option.layoutCenter = ['50%', '80%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'BLG',
          })
          break
        case 'ALK' :
          this.option.layoutCenter = ['45%', '60%'],
              this.option.layoutSize = this.setLayoutSzie,
              this.myEchart.setOption(this.option, true);
          this.myEchart.dispatchAction({
            type: 'geoToggleSelect',
            name: 'ALK',
          })
          break
      }

    },
    drawChart() {


      this.myEchart = echarts.init(document.getElementById("main"));

      console.log(1);

      axios("./page88BG.svg").then((svg) => {
            // console.log(svg, 'svgsvg')
            echarts.registerMap('seats', {svg: svg.data})
            this.myEchart.setOption(this.option);
        this.myEchart.on('geoselectchanged',  (params)=> {
          console.log(params,'this.myEchart')
          this.$emit('changeName',params.name )
        });
          }
      );
    },
  },
  mounted() {

    this.drawChart();

  },

};

</script>

<style scoped>

#main {
  width: 100vw;

  height: 40vh;


}

</style>
