<template>
  <div v-if=" $i18n.locale === 'ZH'" class="page5 flex-col">
    <div class="group_1 flex-row">
      <div class="section_1 flex-col"></div>
      <span class="text_1"></span>
      <div class="section_2 flex-col"></div>
      <div class="text-wrapper_1">
        <span class="text_2"></span>
        <span class="text_3"></span>
        <span class="text_4"></span>
      </div>
    </div>
    <div class="group_2 flex-col">
      <div class="group_3 flex-col">
        <div class="box_1 flex-col">
          <div class="group_4 flex-col"></div>
          <div class="group_5 flex-col">
            <div class="group_6 flex-col">
              <div class="group_7 flex-col"></div>
              <span class="text_5">本科科研&nbsp;求索新知</span>
            </div>
          </div>
        </div>
      </div>
      <div class="text-wrapper_2 flex-col">
        <span class="text_6">
          上海纽约大学高度重视培养本科生的科研创新能力，为他们提供接触参与前沿科学研究，与专家深度交流的机会，培育具有实践和创新能力的本科人才。学生可根据自身兴趣能力，与导师共同进行诸多科研创新项目的合作。每年都有学生在顶尖国际期刊或学术会议上发表论文。
        </span>
      </div>
      <div class="group_8 flex-row">
        <div class="text-group_1 flex-col">
          <span class="text_7">以项目为单位的学术训练</span>
          <span class="text_8">
            有别于以课程为单位的“片段式”科研体验，学生所能接触到的科研机会注重自始至尾的全局性，以完整的学术训练为载体，培养学生具备独立严谨及审辩求证的科研能力。
          </span>
        </div>
      </div>
      <div class="group_9 flex-row">
        <div class="text-group_2 flex-col">
          <span class="text_9">本科生科研基金项目</span>
          <span class="text_10">
            学校鼓励并资助各年级学生开展任一学科领域的暑期研究课题，培养学术好奇心，在实践中训练科研思维。学生可以个人或小组形式，提出研究课题方向与方案，由导师一对一指导开展，并可申领7000元的研究基金。
          </span>
        </div>
      </div>
      <div class="group_10 flex-row">
        <div class="text-group_3 flex-col">
          <span class="text_11">本科生科研专题讨论会</span>
          <span class="text_12">
            旨在鼓励来自各学科领域的大四毕业生展示个人研究项目及成果，覆盖获荣誉学士学位学生的个人项目，以及作为毕业项目、独立研究项目或本科生科研基金项目的研究论文及创意作品。
          </span>
        </div>
      </div>
    </div>
    <div class="group_11 flex-row">
      <div class="text-group_4 flex-col">
        <span class="text_13">高水平科研机构</span>
        <span class="text_14">
          上海纽约大学在跨学科、跨地域的学术研究和合作方面发挥特殊的制度优势，充分利用高水平国际化科研平台，创办了6个联合研究中心和7个校级研究中心。范围涵盖：数学、物理、计算化学、社会发展、脑与认知科学、全球历史经济文化、金融波动、应用社会经济、环球亚洲、数据科学与人工智能、商学、人工智能与文化、城市与不动产等研究领域。
        </span>
      </div>
    </div>

    <div class="middleTitle">
      <span class="text_6">学生感言</span>
      <span class="text_7 eng">/STUDENT&nbsp;JOURNEY</span>
    </div>
    <nut-collapse style="width: 95vw" v-model="activeNames">
      <nut-collapse-item title="马家骥&nbsp;2021届" :name="1">
        <div class="cardA">
          良好的科研氛围是我选择上海纽约大学的重要因素之一。学校实行小班化教学，学生有很多与教授一对一交流的机会，这意味着我可以为了知识本身而学习，而不用过分担心成绩或排名。入学第二周，我就主动找物理学助理教授Tim
          Byrnes了解学校的科研机会。面试后，他邀请我一同参加每天的研究组会。自大二起，我在物理学助理教授陈航晖的指导下接受科研训练。三年来，我学到了很多，也取得了一定成果——参与发表了两篇研究论文，其中一篇发表在国际跨学科科学期刊《自然-通讯》上，并在两场国际会议上展示了研究成果。
        </div>
        <div class="cardB flex-col">
          <div class="cardC">
            <div style="    margin: 2vw;">
                              <span class="paragraph_2">
                                                                             <br/>

                                  毕业中学：山东省实验中学
                                  <br/>
                                  <br/>
                                  <br/>
                                  本科专业：物理学  数学

                                  <br/>
                                  <br/>
                                  <br/>
                                  毕业去向：耶鲁大学应用物理学  博士研究生
                                </span>
            </div>

          </div>

        </div>
      </nut-collapse-item>

    </nut-collapse>
    <myfooter></myfooter>
  </div>
  <div v-else class="page5En flex-col eng">
    <div class="box_2 flex-col">
      <div class="box_3 flex-col"></div>
    </div>
    <div class="group_11 flex-col">
      <div class="text-wrapper_2 flex-col" style="margin:  63.13vw 0 0 4.26vw">
        <span class="text_6">
As a student at NYU Shanghai, you have the ability to do your own research and find solutions to today’s greatest challenges. Research-related opportunities include:
        </span>
      </div>
      <div class="text-wrapper_7 flex-col">
        <span class="text_7" style="margin-top: 3.4vw">
Deans’ Undergraduate Research Fund, which awards funding to undergraduates at NYU Shanghai for summer research in any field of study. You can work alone or in a group, in a traditional or interdisciplinary field, in a professor’s lab or in a studio—it’s up to you.
        </span>
      </div>
      <div class="text-wrapper_5 flex-col">
        <span class="text_9">
Undergraduate Research Symposium, a university-wide celebration of research conducted by graduating seniors spanning the arts, sciences, engineering, and business fields.
        </span>
      </div>
      <div class="box_11 flex-col" style="margin-top: 10vw">
        <div class="text-wrapper_6">
          <span class="text_10">GET&nbsp;IN&nbsp;ON&nbsp;THE</span>
          <span class="paragraph_1">
            <br/>
            INNOVATION
          </span>
        </div>
      </div>
      <div class="text-wrapper_8 flex-col">
        <span class="text_8">
          Undergraduate Research Symposium, a university-wide celebration of research conducted by graduating seniors spanning the arts, sciences, engineering, and business fields.
        </span>
      </div>

    </div>
    <div class="text-wrapper_8 flex-col" style="top: 184.2vw;">
        <span class="text_8">
       NYU Shanghai/East China Normal University research institutes, which sponsor world-class research in mathematical sciences, computational chemistry, data science and analytics, physics, and more.
        </span>
    </div>
    <myfooter :eng="true"></myfooter>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
      activeNames: [1, 2],
    };
  },
  methods: {},
  mounted() {
    this.$emit('changeView')
  }
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss"/>
