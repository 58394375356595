import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_2shuzishuohua from '../views/lanhu_2shuzishuohua/index.vue'
// import lanhu_1shouyedanye from '../views/lanhu_1shouyedanye/index.vue'
import lanhu_11shenqingliucheng from '../views/lanhu_11shenqingliucheng/index.vue'
import lanhu_9diquyoushi from '../views/lanhu_9diquyoushi/index.vue'
import lanhu_88quanqiujiaoxuetixizhankai from '../views/lanhu_88quanqiujiaoxuetixizhankai/index.vue'
import lanhu_7biyequxiang from '../views/lanhu_7biyequxiang/index.vue'
import lanhu_12lianxiwomen from '../views/lanhu_12lianxiwomen/index.vue'
import lanhu_8quanqiujiaoxuetixi from '../views/lanhu_8quanqiujiaoxuetixi/index.vue'
import lanhu_6xueyetixi from '../views/lanhu_6xueyetixi/index.vue'
import lanhu_4shizituandui from '../views/lanhu_4shizituandui/index.vue'
import lanhu_3zhuanyekecheng from '../views/lanhu_3zhuanyekecheng/index.vue'
import lanhu_5benkekeyan from '../views/lanhu_5benkekeyan/index.vue'
import lanhu_10zhiyeguihua from '../views/lanhu_10zhiyeguihua/index.vue'
import lanhu_1shouye from '../views/lanhu_1shouye/index.vue'
// import lanhu33 from '../views/lanhu_333zhuanyekecheng/index.vue'
import layout from '../views/layout/index.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [

    {
        path: '/',
        name: '',
        component: layout,
        children: [
            {
                path: '/',
                redirect: "/lanhu_1shouye"

            },
            {
                path: '/lanhu_1shouye',
                name: 'lanhu_1shouye',
                component: lanhu_1shouye,
                showName:"首页",
                ENName:"Homepage"
            },
            // {
            //     path: '/lanhu_1shouyedanye',
            //     name: 'lanhu_1shouyedanye',
            //     component: lanhu_1shouyedanye,
            // },
            {
                path: '/lanhu_2shuzishuohua',
                name: 'lanhu_2shuzishuohua',
                component: lanhu_2shuzishuohua,
                showName:"用数字说话",
                ENName:"Highlights"

            },
            // {
            //     path: '/lanhu33',
            //     name: 'lanhu33',
            //     component: lanhu33,
            //     showName:"33"
            //
            // },
            {
                path: '/lanhu_3zhuanyekecheng',
                name: 'lanhu_3zhuanyekecheng',
                component: lanhu_3zhuanyekecheng,
                showName:"专业课程",
                ENName:"Course"

            },
            {
                path: '/lanhu_4shizituandui',
                name: 'lanhu_4shizituandui',
                component: lanhu_4shizituandui,
                showName:"师资团队",
                ENName:"Faculty"
            },
            {
                path: '/lanhu_5benkekeyan',
                name: 'lanhu_5benkekeyan',
                component: lanhu_5benkekeyan,
                showName:"本科科研",
                ENName:"Undergraduate Research"
            },
            {
                path: '/lanhu_6xueyetixi',
                name: 'lanhu_6xueyetixi',
                component: lanhu_6xueyetixi,
                showName:"学业体系",
                ENName:"Chinese Learning"
            },
            {
                path: '/lanhu_7biyequxiang',
                name: 'lanhu_7biyequxiang',
                component: lanhu_7biyequxiang,
                showName:"毕业去向",
                ENName:"Graduate Destination"
            },
            {
                path: '/lanhu_8quanqiujiaoxuetixi',
                name: 'lanhu_8quanqiujiaoxuetixi',
                component: lanhu_8quanqiujiaoxuetixi,
                showName:"全球教学体系",
                ENName:"NYU Global Network"
            },
            {
                path: '/lanhu_9diquyoushi',
                name: 'lanhu_9diquyoushi',
                component: lanhu_9diquyoushi,
                showName:"地区优势",
                ENName:"Locations"
            },
            {
                path: '/lanhu_10zhiyeguihua',
                name: 'lanhu_10zhiyeguihua',
                component: lanhu_10zhiyeguihua,
                showName:"职业规划",
                ENName:"Career Development",
                OnlyCn:"Career Development"
            },
            {
                path: '/lanhu_11shenqingliucheng',
                name: 'lanhu_11shenqingliucheng',
                component: lanhu_11shenqingliucheng,
                showName:"申请流程",
                ENName:"Application Procedure"
            },
            {
                path: '/lanhu_12lianxiwomen',
                name: 'lanhu_12lianxiwomen',
                component: lanhu_12lianxiwomen,
                showName:"联系我们",
                ENName:"Contact Us"
            },
            {
                path: '/lanhu_88quanqiujiaoxuetixizhankai',
                name: 'lanhu_88quanqiujiaoxuetixizhankai',
                component: lanhu_88quanqiujiaoxuetixizhankai,
                // showName:"全全球222"
            },
        ]
    },

]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
