<template>

  <!--  <div class="group_2 flex-col"></div>-->
  <!--  圆点-->
  <div class="page88">
    <div style="width: 100vw;height: 40vh ;margin-top: 14.94vw;">
      <demo ref="Emap" @changeName="handleChangeName"></demo>
    </div>
    <div v-if=" $i18n.locale === 'ZH'" class="block_4 flex-col">
      <div v-if="change === 'center'" class="box_13 flex-row justify-between" style=" overflow-y: hidden">
        <swiper
            style=" width: 30vw ;"
            :centeredSlides="true"
            :pagination="{clickable: true,}"
            class="mySwiper"
            ref="mySwiper"
        >
          <swiper-slide key="LA"
          >
            <div @click="handleMoveMap('LA')" class="image-text_5 country flex-col">
              <div class="section_4 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">美国</span>
                <span class="text_3">洛杉矶</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('DC')" class="image-text_5 country flex-col">
              <div class="section_5 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">美国</span>
                <span class="text_3">华盛顿特区</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('LD')" class="image-text_5 country flex-col">
              <div class="section_6 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">英国</span>
                <span class="text_3">伦敦</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('FLLS')" class="image-text_5 country flex-col">
              <div class="section_7 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">意大利</span>
                <span class="text_3">佛罗伦萨</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('MDL')" class="image-text_5 country flex-col">
              <div class="section_8 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">西班牙</span>
                <span class="text_3">马德里</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('BL')" class="image-text_5 country flex-col">
              <div class="section_9 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">德国</span>
                <span class="text_3">柏林</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('BYNSALS')" class="image-text_5 country flex-col">
              <div class="section_10 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">阿根廷</span>
                <span class="text_3">布宜诺斯艾利斯</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('paris')" class="image-text_5 country flex-col">
              <div class="section_11 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">法国</span>
                <span class="text_3">巴黎</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('TLWF')" class="image-text_5 country flex-col">
              <div class="section_12 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">以色列</span>
                <span class="text_3">特拉维夫</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('ALK')" class="image-text_5 country flex-col">
              <div class="section_13 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">加纳</span>
                <span class="text_3">阿拉克</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('BLG')" class="image-text_5 country flex-col">
              <div class="section_14 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">捷克</span>
                <span class="text_3">布拉格</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('XN')" class="image-text_5 country flex-col">
              <div class="section_15 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">澳大利亚</span>
                <span class="text_3">悉尼</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>

      </div>
      <div v-if="change !== 'center'" class="box_13 flex-row justify-between" style="overflow-y: hidden">
        <div style="display: flex;justify-content: space-between;width: 100%">
          <div :class="{active:threeActive === 0}" @click="handleMoveMap('shanghai')" class="image-text_5 country flex-col">
            <div class="section_1 flex-col"></div>
            <div class="text-group_7 flex-row">
              <div class="text_2">中国</div>
              <div class="text_3">上海</div>
            </div>
          </div>
          <div :class="{active:threeActive === 1}" @click="handleMoveMap('newyork')" class="image-text_5 country flex-col">
            <div class="section_2 flex-col"></div>
            <div class="text-group_7 flex-row">
              <div class="text_2">美国</div>
              <div class="text_3" style="text-align: left;" >纽约</div>
            </div>
          </div>
          <div :class="{active:threeActive === 2}" @click="handleMoveMap('abzb')" class="image-text_5 country flex-col">
            <div class="section_3 flex-col"></div>
            <div class="text-group_7 flex-row">
              <span class="text_2">阿联酋</span>
              <span class="text_3">阿布扎比</span>
            </div>
          </div>
        </div>
<!--        <swiper-->
<!--            style=" width: 30vw ;"-->
<!--            :centeredSlides="true"-->
<!--            :pagination="{clickable: true,}"-->
<!--            class="mySwiper"-->
<!--            ref="mySwiper"-->
<!--        >-->
<!--          <swiper-slide key="LA">-->
<!--            <div @click="handleMoveMap('shanghai')" class="image-text_5 country flex-col">-->
<!--              <div class="section_1 flex-col"></div>-->
<!--              <div class="text-group_7 flex-row">-->
<!--                <div class="text_2">中国</div>-->
<!--                <div class="text_3">上海</div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </swiper-slide>-->


<!--          <swiper-slide key="LA">-->
<!--            <div @click="handleMoveMap('newyork')" class="image-text_5 country flex-col">-->
<!--              <div class="section_2 flex-col"></div>-->
<!--              <div class="text-group_7 flex-row">-->
<!--                <div class="text_2">美国</div>-->
<!--                <div class="text_3" style="text-align: left">纽约</div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </swiper-slide>-->
<!--          <swiper-slide key="LA">-->
<!--            <div @click="handleMoveMap('abzb')" class="image-text_5 country flex-col">-->
<!--              <div class="section_3 flex-col"></div>-->
<!--              <div class="text-group_7 flex-row">-->
<!--                <span class="text_2">阿联酋</span>-->
<!--                <span class="text_3">阿布扎比</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </swiper-slide>-->
<!--        </swiper>-->
      </div>
      <div class="box_14 flex-row justify-between">
        <div @click="handleChangeList('center')" :class="{active:change === 'center' }" class="box_15 flex-row">
          <div class="text-group_11 flex-col">
            <span class="text_10">12</span>
            <span class="text_11">海外学习中心</span>
          </div>
        </div>
        <div @click="handleChangeList('school')" :class="{active:change === 'school'}" class="box_16 flex-row">
          <div class="text-group_12 flex-col">
            <span class="text_12">3</span>
            <span class="text_13">门户校园</span>
          </div>
        </div>
      </div>
<!--      <div class="box_14 flex-row justify-between">-->
<!--        <div style="padding: 5.33vw 0.33vw 2.66vw 3.6vw;" @click="handleChangeList('center')"-->
<!--             :class="{active:change === 'center' }" class="box_15 flex-row">-->
<!--          <div class="text-group_11 flex-col">-->
<!--            <span class="text_10">12</span>-->
<!--            <span class="text_11">-->
<!--       海外学习中心-->
<!--             </span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="padding: 5.33vw 3.33vw 2.66vw 3.6vw" @click="handleChangeList('school')"-->
<!--             :class="{active:change === 'school'}" class="box_16 flex-row">-->
<!--          <div class="text-group_12 flex-col">-->
<!--            <span class="text_12">3</span>-->
<!--            <span class="text_13">门户校园</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <button style="    padding: 4.26vw 35.73vw 4.26vw 30.53vw;" class="text-wrapper_2 flex-col" @click="onClick_1">
        <span class="text_14">返回首页</span></button>
    </div>
    <div v-else class="block_4 flex-col eng">
      <div v-if="change === 'center'" class="box_13 flex-row justify-between" style=" overflow-y: hidden">
        <swiper
            style=" width: 30vw ;"
            :centeredSlides="true"
            :pagination="{clickable: true,}"
            class="mySwiper"
            ref="mySwiper"
        >
          <swiper-slide key="LA"
          >
            <div @click="handleMoveMap('LA')" class="image-text_5 country flex-col">
              <div class="section_4 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">US</span>
                <span class="text_3">Los Angeles</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('DC')" class="image-text_5 country flex-col">
              <div class="section_5 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">US</span>
                <span class="text_3">Washington,DC</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('LD')" class="image-text_5 country flex-col">
              <div class="section_6 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">England</span>
                <span class="text_3">London</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('FLLS')" class="image-text_5 country flex-col">
              <div class="section_7 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">Italy</span>
                <span class="text_3">Florence</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('MDL')" class="image-text_5 country flex-col">
              <div class="section_8 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">Spain</span>
                <span class="text_3">Madrid</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('BL')" class="image-text_5 country flex-col">
              <div class="section_9 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">Germany</span>
                <span class="text_3">Berlin</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('BYNSALS')" class="image-text_5 country flex-col">
              <div class="section_10 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">Argentina</span>
                <span class="text_3">Buenos Aires</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('paris')" class="image-text_5 country flex-col">
              <div class="section_11 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">France</span>
                <span class="text_3">Paris</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('TLWF')" class="image-text_5 country flex-col">
              <div class="section_12 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">Israel</span>
                <span class="text_3">Tel Aviv</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('ALK')" class="image-text_5 country flex-col">
              <div class="section_13 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">Ghana</span>
                <span class="text_3">Accra</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('BLG')" class="image-text_5 country flex-col">
              <div class="section_14 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">Czech</span>
                <span class="text_3">Prague</span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div @click="handleMoveMap('XN')" class="image-text_5 country flex-col">
              <div class="section_15 flex-col"></div>
              <div class="text-group_7 flex-row">
                <span class="text_2">Australia</span>
                <span class="text_3">Sydney</span>
              </div>
            </div>
          </swiper-slide>
        </swiper>

      </div>
      <div v-if="change !== 'center'" class="box_13 flex-row justify-between" style="overflow-y: hidden">
        <div style="display: flex;justify-content: space-between;width: 100%">
          <div :class="{active:threeActive === 0}" @click="handleMoveMap('shanghai')" class="image-text_5 country flex-col">
            <div class="section_1 flex-col"></div>
            <div class="text-group_7 flex-row">
              <div class="text_2">China</div>
              <div class="text_3">Shanghai</div>
            </div>
          </div>
          <div :class="{active:threeActive === 1}" @click="handleMoveMap('newyork')" class="image-text_5 country flex-col">
            <div class="section_2 flex-col"></div>
            <div class="text-group_7 flex-row">
              <div class="text_2">US</div>
              <div class="text_3" style="text-align: left;width: 24vw" >New York City</div>
            </div>
          </div>
          <div :class="{active:threeActive === 2}" @click="handleMoveMap('abzb')" class="image-text_5 country flex-col">
            <div class="section_3 flex-col"></div>
            <div class="text-group_7 flex-row">
              <span class="text_2">UAE</span>
              <span class="text_3">Abu Dhabi</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box_14 flex-row justify-between">
        <div style="padding: 5.33vw 0.33vw 2.66vw 3.6vw;" @click="handleChangeList('center')"
             :class="{active:change === 'center' }" class="box_15 flex-row">
          <div class="text-group_11 flex-col">
            <span class="text_10">12</span>
            <span class="text_11">
              <div>
                  global academic centers
              </div>
              <div>
           and programs
              </div>
             </span>
          </div>
        </div>
        <div style="padding: 5.33vw 3.33vw 2.66vw 3.6vw" @click="handleChangeList('school')"
             :class="{active:change === 'school'}" class="box_16 flex-row">
          <div class="text-group_12 flex-col">
            <span class="text_12">3</span>
            <span class="text_13">degree-granting camp</span>
          </div>
        </div>
      </div>
      <button style="    padding: 4.26vw 35.73vw 4.26vw 30.53vw;" class="text-wrapper_2 flex-col" @click="onClick_1">
        <span class="text_14">Back HomePage</span></button>
    </div>

    <myfooter :eng="true" style="margin-top: 5vw"></myfooter>
  </div>
</template>
<script>
// import * as echarts from 'echarts';
import demo from '@/components/map.vue'
// import axios from 'axios';

// import {registerMap} from "echarts/core";
export default {
  components: {
    'demo': demo
  },
  data() {
    return {
      constants: {},
      change: 'center',
      swiperIndex: 0,
      threeActive: 1,
      list3: [
        {name: "abzb", index: 2},
        {name: "NEWYORK", index: 1},
        {name: "shanghai", index: 0}
      ],
      list12: [
        {name: "DC", index: 1},
        {name: "LD", index: 2},
        {name: "FLLS", index: 3},
        {name: "MDL", index: 4},
        {name: "BL", index: 5},
        {name: "BYNSALS", index: 6},
        {name: "paris", index: 7},
        {name: "ALK", index: 9},
        {name: "BLG", index: 10},
        {name: "TLWF", index: 8},
        {name: "XN", index: 11},
        {name: "LA", index: 0},]
    };
  },
  mounted() {
      this.$emit('changeView')
//     var myChart = echarts.init(document.getElementById('main'));
//     axios(svg).then(res=>{
//       let chinasvg = res.data;
//       myChart.registerMap('china', { svg: chinasvg });
//     })
// // 绘制图表
//    let option = {
//       tooltip: {},
//     };
//     myChart.setOption(option);
  },
  methods: {
    handleChangeList(e) {
      this.change = e
    },
    handleChangeSwiper() {
      this.swiperIndex = this.$refs.mySwiper.swiper.activeIndex
    },
    handleChangeName(e) {
      console.log(this.$refs.mySwiper)
      console.log(e, 'namess')
      let isInThree = false
      let index = 1
      for (const eElement of this.list3) {
        if (eElement.name === e) {
          isInThree = true
          index = eElement.index
          this.threeActive = index
        }
      }
      if (isInThree) {
        this.change = 'school'
        console.log(index,'indexindex')
        // this.$refs.mySwiper.swiper.slideTo(index);

      } else {
        for (const eElement of this.list12) {
          if (eElement.name === e) {
            isInThree = true
            index = eElement.index
          }
        }
        this.change = 'center'

        this.$refs.mySwiper.swiper.slideTo(index);
      }
    },
    handleMoveMap(city) {
      this.$refs.Emap.showCity(city)
    },
    top() {
      return document.documentElement.clientHeight * 0.1;
    },
    left() {
      return 0;
    },
    right() {
      return 0;
    },
    bottom() {
      return document.documentElement.clientHeight * 0.5;
    },
    onClick_1() {
      this.$router.push('/')
    }
  }
};
</script>
<style scoped lang="scss" src="./assets/index.response.scss"/>
