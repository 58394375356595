export const h = {
  system: "Background management system",
  full: "full-screen display",
  account: "myAccount",
  invoice: "invoice",
  reconciliation: "Statement",
  record: "recording",
  report: "report",
  setting: "Settings",
  login: "login",
  tips: "Username and password are filled in casually",
  administrator: "administrator",
  placeUser: "please enter user name",
  palcePass: "Please enter your password",
  palceCode: "please enter verification code",
  accounts: "accounts",
  password: "password",
  code: "Verification code"
}